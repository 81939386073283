import React from 'react';
import { styled } from '@glitz/react';
import { translate } from '@avensia/scope';
import Html from 'Shared/Partials/PropertyTypes/Html';
import {
  large,
  tundora,
  sigma,
  epsilon,
  delta,
  gamma,
  minMediumMediaQuery,
  pixelsToUnit,
  transition,
} from 'Shared/Style';
import { Basic } from 'Shared/PageLayout';

type PropType = {
  description?: Scope.XhtmlString;
  forwardedRef: React.Ref<HTMLDivElement>;
  isCompact: boolean;
};

type StateType = {
  isExpanded: boolean;
  height: string | number;
  isMounted: boolean;
};

class Description extends React.Component<PropType, StateType> {
  ref: HTMLDivElement;
  constructor(props: PropType) {
    super(props);
    this.state = {
      isExpanded: false,
      height: DEFAULT_HEIGHT,
      isMounted: false,
    };
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  handleShowMore = () => {
    this.setState((prevState) => ({
      ...prevState,
      isExpanded: true,
      height: this.ref.clientHeight,
    }));
  };

  render() {
    const isShowMore = this.props.isCompact && this.state.isMounted && this.ref.clientHeight > this.state.height;
    return (
      <Base elementRef={this.props.forwardedRef}>
        <H2>{translate('/Product/Description/Heading')}</H2>
        <Content
          css={{
            height: isShowMore || this.state.isExpanded ? this.state.height : 'auto',
            overflowY: 'hidden',
          }}
        >
          <styled.Div ref={(el: HTMLDivElement) => (this.ref = el)}>
            <Html
              html={this.props.description.html}
              className="product-description"
              // tslint:disable-next-line:jsx-no-lambda
              component={(innerProps) => <Div {...innerProps} />}
            />
          </styled.Div>
        </Content>
        {isShowMore && !this.state.isExpanded && (
          <styled.Div onClick={this.handleShowMore}>
            <FadeOut />
            <ShowMore>{translate('/Product/Description/ShowAll')}</ShowMore>
          </styled.Div>
        )}
      </Base>
    );
  }
}

export default Description;

const DEFAULT_HEIGHT = 215;

const Base = styled(Basic, {
  textAlign: 'center',
  marginBottom: pixelsToUnit(50),
  [minMediumMediaQuery]: {
    textAlign: 'left',
    width: '50%',
    paddingRight: '2rem',
    marginBottom: pixelsToUnit(0),
  },
});
const H2 = styled.h2({
  fontSize: delta,
  marginBottom: large,
  letterSpacing: pixelsToUnit(2),
  lineHeight: 'normal',
  textTransform: 'uppercase',
  [minMediumMediaQuery]: {
    fontSize: gamma,
    marginBottom: pixelsToUnit(20),
    letterSpacing: pixelsToUnit(2.3),
  },
});

const Div = styled.div({
  fontSize: sigma,
  color: tundora,
  lineHeight: 1.5,
  textAlign: 'left',
  overflowWrap: 'break-word',
  margin: {
    x: 'auto',
  },
  [minMediumMediaQuery]: {
    maxWidth: '100%',
    fontSize: epsilon,
  },
});

const Content = styled.div(
  transition({
    property: 'height',
    duration: 100,
  }),
);

const FadeOut = styled.div({
  position: 'relative',
  bottom: pixelsToUnit(64),
  height: pixelsToUnit(64),
  backgroundImage: 'linear-gradient( rgba(255, 255, 255, 0) 0%,  rgba(255, 255, 255, 1) 100% )',
});
const ShowMore = styled.div({
  marginTop: pixelsToUnit(-50),
  textDecoration: 'underline',
  cursor: 'pointer',
  fontSize: sigma,
});
