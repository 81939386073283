export type ValuePropType = {
  hasValue: boolean;
};

export type ViewportPropType = {
  isCompact?: boolean;
};

export const COLOR_TRANSPARENT = 'rgba(79,79,79, 0)';
export const COLOR_NOT_TRANSPARENT = 'rgba(79,79,79, 1)';
