import React from 'react';
import connect from 'Shared/connect';
import { updateColor, updateFont, updateText, updateWoffValue } from './action-creators';
import { ProductDesignerType } from 'Shared/State';
import { styled } from '@glitz/react';
import ProductDesignerConfigurator from './configurator';
import './style.scss';

type ConnectStateType = ProductDesignerType;

type ConnectActionType = {
  updateColor: (name: string, value: string) => void;
  updateFont: (value: string) => void;
  updateText: (value: string) => void;
  updateWoffValue: (value: string) => void;
};

type PropType = ConnectStateType & ConnectActionType;

class ProductDesigner extends React.Component<PropType> {
  render() {
    return (
      <styled.Div
        style={{
          height: '10rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <styled.Div
          style={{
            background: '#000',
            lineHeight: '4rem',
            width: '100%',
            marginLeft: '1rem',
            marginRight: '1rem',
            textAlign: 'center',
            fontSize: '3.5rem',
            color: this.props.color,
            fontFamily: this.props.woffValue,
            border: '2px dashed #777',
            borderRadius: '5px',
            boxShadow: '0 0 0 4px #000, 2px 1px 6px 4px rgba(10, 10, 0, 0.5)',
          }}
        >
          {this.props.text}
        </styled.Div>
      </styled.Div>
    );
  }
}

export default connect(
  (state): ConnectStateType => ({
    colorName: state.productDesigner.colorName,
    color: state.productDesigner.color,
    font: state.productDesigner.font,
    woffValue: state.productDesigner.woffValue,
    text: state.productDesigner.text,
  }),
  (dispatch): ConnectActionType => ({
    updateColor(colorName, value) {
      return dispatch(updateColor(colorName, value));
    },
    updateFont(value) {
      return dispatch(updateFont(value));
    },
    updateWoffValue(value) {
      return dispatch(updateWoffValue(value));
    },
    updateText(value) {
      return dispatch(updateText(value));
    },
  }),
)(ProductDesigner);

export { ProductDesignerConfigurator };
