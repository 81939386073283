import React, { useState } from 'react';
import { epiPropertyValue } from '@avensia/scope-episerver';
import Link from 'Shared/Link';
import UspBullets from './../../Product/ProductDetails/UspBullets';
import { DiscountTriBadge } from '../../Product/ProductCard/Badge';
import { styled } from '@glitz/react';
import {
  minMediumMediaQuery,
  minLargeMediaQuery,
  minSmallMediaQuery,
  minTinyMediaQuery,
  white,
  pixelsToUnit,
} from 'Shared/Style';
import Check from 'Shared/Icon/Check';
import { translate } from '@avensia/scope';

type RequiredPropType = {
  product: any;
  handleDeselect: (products: any) => void;
  handleReselect: (products: any) => void;
};

type PropType = RequiredPropType;

export default function CommonPurchaseCombinationProduct(props: PropType) {
  const [checked, setChecked] = useState(true);
  const productImage = props.product.imageUrls.length > 0 ? props.product.imageUrls[0] : '';
  let LimitedBullets: string[] = props.product.bulletPoints;
  LimitedBullets = props.product.bulletPoints.slice(0, 5);
  const variationCode = epiPropertyValue(props.product.variation.code);
  let data = {
    code: variationCode,
    ticket: props.product.ticket,
    currentPrice: props.product.price.current,
    originalPrice: props.product.price.original,
  };

  const handleCheck = () => {
    if (checked) {
      setChecked(false);
      props.handleDeselect(data);
    } else {
      setChecked(true);
      props.handleReselect(data);
    }
  };
  return (
    <ProductOuterContainer>
      <ProductContainer>
        {props.product.price.discountPercentage > 0 && (
          <DiscountTriBadge css={{ left: 0 }}>-{props.product.price.discountPercentage}%</DiscountTriBadge>
        )}
        <ProductImageContainer>
          <ProductImage to={props.product.url}>
            <img
              alt={props.product.brand}
              itemProp="image"
              src={productImage}
              style={{ mixBlendMode: 'multiply', objectFit: 'contain' }}
            />
          </ProductImage>
        </ProductImageContainer>
        <Wrapper>
          <NameBulletsContainer>
            <ProductName>{epiPropertyValue(props.product.variation.displayName)}</ProductName>
            <BulletPoints
              bulletPoints={LimitedBullets}
              hasValue={props.product.bulletPoints && props.product.bulletPoints.length > 0}
            />
          </NameBulletsContainer>
          <CheckboxContainer>
            <CheckboxText>{translate('/Product/ComboProductCheckboxLabel')}</CheckboxText>
            <CustomCheckbox onClick={() => handleCheck()}>{checked && <CheckStyled />}</CustomCheckbox>
          </CheckboxContainer>
        </Wrapper>
      </ProductContainer>
    </ProductOuterContainer>
  );
}

const ProductOuterContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [minLargeMediaQuery]: {
    flexDirection: 'row',
  },
});

const ProductContainer = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: '15rem',
  width: '9rem',
  alignItems: 'center',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'center',
  margin: { xy: '0.3rem' },
  padding: { xy: '0.4rem' },
  backgroundColor: (theme) => theme.cardBackgroundColor,
  boxShadow: (theme) => `0 0.3rem 1rem 0 rgba(${theme.shadowColor}, 0.3)`,
  [minTinyMediaQuery]: {
    width: '12rem',
  },
  [minSmallMediaQuery]: {
    flexDirection: 'row',
    width: '28rem',
  },
  [minLargeMediaQuery]: {
    flexDirection: 'column',
    height: '28rem',
    width: '16rem',
    padding: { xy: '1rem' },
  },
});

const ProductName = styled.div({
  marginTop: pixelsToUnit(5),
  fontWeight: 'bold',
  textAlign: 'left',
  fontSize: '0.7rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  display: '-webkit-box',
  [minSmallMediaQuery]: {
    fontSize: '1rem',
  },
});

const ProductImageContainer = styled.div({
  height: '140px',
  width: '140px',
  [minSmallMediaQuery]: {
    height: '180px',
    width: '180px',
  },
});

const ProductImage = styled(Link, {
  height: '140px',
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  width: '140px',
  [minSmallMediaQuery]: {
    width: '10rem',
    height: '10rem',
    padding: { xy: '0.5rem' },
  },
  [minMediumMediaQuery]: {
    width: pixelsToUnit(180),
    height: pixelsToUnit(180),
  },
});

const BulletPoints = styled(UspBullets, {
  display: 'none',
  maxWidth: '15rem',
  [minSmallMediaQuery]: {
    display: 'block',
  },
  [minMediumMediaQuery]: {
    maxWidth: '20rem',
  },
});

const NameBulletsContainer = styled.div({
  margin: { x: '0.5rem', y: 0 },
  [minSmallMediaQuery]: {
    width: '12rem',
    margin: { x: '1rem', y: 0 },
    height: pixelsToUnit(190),
  },
  [minMediumMediaQuery]: {
    width: '14rem',
  },
  [minLargeMediaQuery]: {
    width: '100%',
    margin: { x: 0, y: 0 },
  },
});

const CustomCheckbox = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '20px',
  height: '20px',
  backgroundColor: (theme) => theme.buyButtonProductcardDesktop,
  alignSelf: 'center',
  [minSmallMediaQuery]: {
    width: '25px',
    height: '25px',
  },
  [minMediumMediaQuery]: {
    width: '30px',
    height: '30px',
  },
});

const Wrapper = styled.div({
  height: pixelsToUnit(80),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [minSmallMediaQuery]: {
    height: 'unset',
  },
  [minLargeMediaQuery]: {
    height: '100%',
    width: '100%',
  },
});

const CheckStyled = styled(Check, {
  color: white,
});

const CheckboxContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  marginTop: pixelsToUnit(10),
});

const CheckboxText = styled.div({
  fontWeight: 'bold',
  marginRight: pixelsToUnit(10),
  lineHeight: pixelsToUnit(28),
});
