import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { minMediumMediaQuery, pixelsToUnit, epsilon, sigma } from 'Shared/Style';
import { translate } from '@avensia/scope';
import Button, { Appearance as ButtonAppearance, Variant as ButtonVariant } from 'Shared/Button';

type PropType = StyledProps & {
  onClick: () => void;
};

const AddReview = styled(Button, {
  display: 'block',
  fontSize: sigma,
  letterSpacing: pixelsToUnit(0.7),
  margin: {
    x: 'auto',
  },
  padding: {
    x: pixelsToUnit(20),
  },
  whiteSpace: 'nowrap',
  textAlign: 'center',
  [minMediumMediaQuery]: {
    fontSize: epsilon,
    letterSpacing: pixelsToUnit(0.8),
    padding: {
      x: pixelsToUnit(33),
    },
  },
});

export default styled(function AddReviewButton(props: PropType) {
  return (
    <AddReview
      css={props.compose()}
      onClick={props.onClick}
      appearance={ButtonAppearance.Secondary}
      variant={ButtonVariant.Large}
    >
      {translate('/Product/Reviews/AddReview')}
    </AddReview>
  );
});
