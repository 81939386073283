/**
 * @ComponentFor VariationViewModel
 */
import React from 'react';
import { Style } from '@glitz/type';
import { styled } from '@glitz/react';
import { translate, Breakpoint, scrollTo, isIE, replaceState } from '@avensia/scope';
import { epiPropertyValue, ContentArea } from '@avensia/scope-episerver';
import Image, { Ratio } from 'Shared/Image/Ratio';
import Link from 'Shared/Link';
import { CacheItem } from '@avensia/scope/CurrentPage/cache';
import { addToCart } from 'Cart/action-creators';
import { getReviews, initializeReviews } from './Reviews/action-creators';
import { Basic, Layout as PageLayout, Appearance } from 'Shared/PageLayout';
import { Actionbar } from 'SiteLayout/Tray';
import VariationViewModelType from './VariationViewModel.type';
import ProductLinkViewModelType from './ProductLinkViewModel.type';
import linkDisplayType from './LinkDisplayType.type';
import connect from 'Shared/connect';
import Viewport from 'Shared/Viewport';
import ProductMedia from './ProductMedia';
import ProductDesigner, { ProductDesignerConfigurator } from './ProductDesigner';
import Cross from 'Shared/Icon/RemovePlain';
import { prepareVariationName } from 'Shared/variation-helper';
import {
  minTinyMediaQuery,
  minMediumMediaQuery,
  minHugeMediaQuery,
  pixelsToUnit,
  kappa,
  white,
  medium,
  zeta,
  eta,
  omega,
  epsilon,
  large,
  delta,
  darkerGray,
  sunsetOrange,
  thin,
  small,
  lightSilver,
  animation,
  transition,
  ZIndex,
  wildwatermelon,
  depth,
  sigma,
} from 'Shared/Style';

import CommonPurchaseCombination from './CommonPurchaseCombination/CommonPurchaseCombination';
import ProductDescriptionSection from './ProductDescriptionSection';
import ReviewSection from './ReviewSection';
import Breadcrumbs from 'Shared/Breadcrumbs';
import { Variant } from 'Shared/Button';
import Icon from 'Shared/Icon/Cart';
import { Theme } from 'Shared/Theming';
import { Brand, ProductName, Prices, UspBullets, SizeGuide } from './ProductDetails';
import ProductActionbar from './ProductActionbar';
import { DesktopSelector as VariantSelector, VariantPanel } from './ProductDetails/VariantSelector';
import AverageRating, { Spacing, Size } from './ProductDetails/AverageRating';
import AddToCart from './ProductAddToCart';
import ProductRelated from './ProductRelated';
import ProductRecentlyViewed from './ProductRecentlyViewed';
import { Check } from 'Shared/Icon/Check-Thin';
import ViewAllReviews from './Reviews/ViewAll';
import { WatchableButton, WatchablePanel } from './WatchableProduct';
import ReviewViewModelType from 'Reviews/Models/ReviewViewModel.type';
import { COLOR_NOT_TRANSPARENT } from './ProductDetails/shared';
import ReviewViewModel from 'Reviews/Models/ReviewViewModel.type';
import GwpFreeItem from './GiftWithPurchase/';
import { ProductDesignerType } from 'Shared/State';
import { DiscountTriBadge } from 'Product/ProductCard/Badge';
import { Down } from 'Shared/Icon/Arrow';

type ConnectStateType = {
  currentTheme: string;
  currentBreakpoint: number;
  allReviews: ReviewViewModelType[];
  reviewsPage: number;
  productReviewId: string;
  designTagProperties: ProductDesignerType;
};

type ConnectActionType = {
  addToCart: (
    code: string,
    quantity: number,
    ticket: string,
    designTagProperties: ProductDesignerType,
  ) => Promise<void>;
  getReviews: (url: string, id: string, page: number) => Promise<void>;
  initializeReviews: (id: string, reviews: ReviewViewModel[], page: number, totalCount: number) => Promise<void>;
};

type PropType = VariationViewModelType &
  ProductLinkViewModelType &
  linkDisplayType &
  CacheItem &
  ConnectStateType &
  ConnectActionType;

type StateType = {
  isPanelOpen: boolean;
  isWatchablePanelOpen: boolean;
  isExpandReviews: boolean;
  isFullscreenViewCarouselOpen: boolean;
  isColorPanelOpen: boolean;
};

const Base = styled(Basic, {
  position: 'relative',
});

const UspContainer = styled.div({
  display: 'flex',
  marginBottom: pixelsToUnit(10),
});

const ImageContainer = styled.div({
  minWidth: pixelsToUnit(45),
  minHeight: pixelsToUnit(45),
  marginRight: pixelsToUnit(7),
});

const MarginContainer = styled(Basic, {
  margin: {
    x: '1rem',
    y: 0,
  },
  [minHugeMediaQuery]: {
    margin: {
      x: 0,
      y: 0,
    },
  },
});

const Wrapper = styled.div({
  backgroundColor: white,
});

const PercentDiscountBadge = styled.div({
  position: 'absolute',
  color: white,
  borderRadius: '100%',
  left: '1rem',
  width: pixelsToUnit(60),
  height: pixelsToUnit(60),
  backgroundColor: (theme) => theme.discountBadgeColor,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: ZIndex.Tray,
  fontWeight: 'bold',
  fontSize: pixelsToUnit(22),
});

const LastBreadcrumb = styled(Breadcrumbs, {
  fontSize: sigma,
  color: (theme) => theme.textColor,
});

const CustomBreadcrumbs = styled(Breadcrumbs, {
  fontSize: eta,
  letterSpacing: pixelsToUnit(0.2),
  margin: {
    y: pixelsToUnit(10),
    x: 0,
  },
  textAlign: 'center',
  [minMediumMediaQuery]: {
    fontSize: kappa,
    marginLeft: pixelsToUnit(20),
    textAlign: 'left',
  },
});

const Campaign = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginTop: pixelsToUnit(5),
  color: wildwatermelon,
  textAlign: 'center',
  fontWeight: 700,
  fontSize: eta,
  textTransform: 'uppercase',
  letterSpacing: pixelsToUnit(0.9),
  ':hover': {
    textDecoration: 'underline',
  },
  [minTinyMediaQuery]: {
    fontSize: kappa,
    letterSpacing: pixelsToUnit(1.1),
  },
  [minMediumMediaQuery]: {
    justifyContent: 'flex-start',
  },
});

const SameDayDelivery = styled.div({
  textAlign: 'center',
  letterSpacing: pixelsToUnit(0.3),
  fontSize: zeta,
  marginTop: pixelsToUnit(14),
  [minMediumMediaQuery]: {
    color: darkerGray,
  },
});

const IconCart = styled(Icon, {
  position: 'relative',
  top: pixelsToUnit(-1),
  left: pixelsToUnit(-5),
  height: pixelsToUnit(22),
});

const AddCart = styled(AddToCart, {
  paddingTop: pixelsToUnit(8),
  lineHeight: 0,
  fontSize: delta,
  margin: {
    y: large,
  },
});

const IconWrap = styled.div({
  paddingBottom: pixelsToUnit(8),
});

const DescriptionAndSizeGuide = styled.div({
  alignItems: 'flex-end',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: large,
});

const AverageContainer = styled.div({
  display: 'block',
  margin: {
    y: pixelsToUnit(16),
    x: 0,
  },
  [minMediumMediaQuery]: {
    display: 'flex',
  },
});

const LastBreadCrumbBrandContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
  marginLeft: pixelsToUnit(-5),
});

const TotalReviews = styled.div({
  fontSize: zeta,
  marginTop: pixelsToUnit(10),
  [minMediumMediaQuery]: {
    fontSize: epsilon,
    margin: {
      left: medium,
      top: 0,
    },
  },
});

const DetailsUsp = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: { x: 0, y: '0.5rem' },
  backgroundColor: (theme) => theme.cardBackgroundColor,
  [minTinyMediaQuery]: {
    justifyContent: 'space-around',
    flexDirection: 'row',
  },
});

const DetailsUspVxn = styled.div({});

const UspItem = styled.span({
  display: 'flex',
  justifyContent: 'flex-start',
  fontSize: zeta,
  [minTinyMediaQuery]: {
    letterSpacing: pixelsToUnit(0.3),
  },
});

const UspItemText = styled.span({
  textAlign: 'left',
  [minTinyMediaQuery]: {
    textAlign: 'center',
  },
});

const BrandAndStockSection = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
});

const ProductDescriptionSectionWrapper = styled.div({
  backgroundColor: white,
  margin: {
    x: 0,
    y: '1rem',
  },
  [minMediumMediaQuery]: {
    padding: {
      xy: '1rem',
    },
  },
});

const Header = styled.h1({
  textAlign: 'center',
  fontSize: '1rem',
  fontWeight: 'bold',
  letterSpacing: pixelsToUnit(1.6),
  textTransform: 'uppercase',
  [minMediumMediaQuery]: {
    fontSize: omega,
  },
});

const Stock = styled.span({
  textAlign: 'center',
  fontSize: kappa,
  fontWeight: 'bold',
  width: '100%',
  letterSpacing: pixelsToUnit(0.3),
  lineHeight: pixelsToUnit(17),
  [minMediumMediaQuery]: {
    textAlign: 'end',
  },
});

const CheckIcon = styled(Check, {
  marginRight: pixelsToUnit(5),
});

const WatchableHelperInfo = styled.p({
  color: sunsetOrange,
  fontSize: epsilon,
  lineHeight: pixelsToUnit(22),
  textAlign: 'left',
  margin: {
    y: pixelsToUnit(10),
  },
});

const Span = styled.span({
  lineHeight: pixelsToUnit(20),
  marginLeft: pixelsToUnit(5),
  marginRight: pixelsToUnit(5),
});

const ShowReviews = styled.div({
  borderBottom: {
    width: thin,
    style: 'solid',
    color: COLOR_NOT_TRANSPARENT,
  },
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: epsilon,
  lineHeight: pixelsToUnit(20),
});

const ReadMore = styled.div({
  cursor: 'pointer',
  lineHeight: pixelsToUnit(20),
  margin: {
    y: small,
  },
  textDecoration: 'underline',
  [minMediumMediaQuery]: {
    margin: 0,
  },
});

const BulletReviewWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column-reverse',
  margin: { x: 0, y: '0.5rem' },
  [minTinyMediaQuery]: {
    flexDirection: 'row',
  },
});

const Details = styled.div({
  margin: { x: 'auto', y: 0 },
  maxWidth: pixelsToUnit(655),
  padding: {
    xy: small,
  },
});

const ProductLinksMenuWrapper = styled.div({
  width: '100%',
  height: '100%',
});

const CurrentProductLink = styled.div({
  width: '2rem',
  height: '2rem',
  borderRadius: '100%',
  border: {
    xy: {
      width: thin,
      style: 'solid',
      color: 'lightgray',
    },
  },
});

const ProductLinksContainer = styled.div({
  position: 'relative',
  height: '3.3rem',
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  paddingRight: pixelsToUnit(18),
  border: {
    xy: {
      width: thin,
      style: 'solid',
      color: lightSilver,
    },
  },
});

const ProductLinkWrapper = styled.div({
  margin: { xy: pixelsToUnit(5) },
  width: pixelsToUnit(180),
  display: 'flex',
  alignItems: 'center',
});

const ColorName = styled.div({});

const ProductLinksMenu = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  ...animation({
    name: {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    duration: '0.5s',
    fillMode: 'both',
  }),
});

const ProductLinkItem = styled(Link, {
  width: '1.5rem',
  height: '1.5rem',
  borderRadius: '100%',
  border: {
    xy: {
      width: thin,
      style: 'solid',
      color: 'lightgray',
    },
  },
  ...transition({ property: 'all', duration: '0.2s', willChange: true, timingFunction: 'ease' }),
  ':hover': {
    width: '100%',
    height: '100%',
  },
});

const ProductLinkItemThumb = styled(Link, {
  margin: { xy: pixelsToUnit(3) },
  ...transition({ property: 'all', duration: '0.2s', willChange: true, timingFunction: 'ease' }),
  ':hover': {
    transform: 'scale(1.15, 1.15)',
    border: {
      xy: {
        width: thin,
        style: 'solid',
        color: 'lightgray',
      },
    },
  },
});

const ProductLinkItemWrapper = styled.div({
  height: pixelsToUnit(35),
  width: pixelsToUnit(35),
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '0.3rem',
  borderRadius: '100%',
  border: {
    xy: {
      width: thin,
      style: 'solid',
      color: 'lightgray',
    },
  },
  ...transition({ property: 'all', duration: '0.5s', willChange: true, timingFunction: 'ease' }),
  ':hover': {
    backgroundColor: 'lightgray',
  },
});

const ProductLinkItemWrapperCurrent = styled(ProductLinkItemWrapper, {
  backgroundColor: 'lightgray',
});

const ProductLinkItemWrapperCurrentThumb = styled.div({
  border: {
    xy: {
      width: thin,
      style: 'solid',
      color: 'lightgray',
    },
  },
  height: '3.3rem',
  width: '3.3rem',
  ...transition({ property: 'all', duration: '0.2s', willChange: true, timingFunction: 'ease' }),
  ':hover': {
    transform: 'scale(1.15, 1.15)',
    backgroundColor: 'white',
  },
});

const ProductNameSmall = styled.div({
  fontSize: pixelsToUnit(17),
  fontWeight: 400,
  textTransform: 'uppercase',
});

const ProductLinksTitle = styled.div({
  textTransform: 'uppercase',
  opacity: 0.4,
  fontSize: pixelsToUnit(14),
  fontWeight: 'bold',
  marginRight: pixelsToUnit(10),
  paddingLeft: pixelsToUnit(20),
});

const ColorMenuButton = styled.div({});

const DownStyled = styled(Down, {
  padding: { xy: pixelsToUnit(2) },
});

const UpStyled = styled(DownStyled, {
  transform: 'rotate(180deg)',
});

const ProductLinksColorWrapper = styled.div({
  zIndex: ZIndex.Tray,
  display: 'flex',
  flexWrap: 'wrap',
  position: 'absolute',
  top: '100%',
  right: 0,
  left: 0,
  transform: 'scaleY(0)',
  transformOrigin: 'center top',
  backgroundColor: white,
  listStyleType: 'none',
  ...transition({ property: 'transform' }),
  ...depth(),
});

// Note that this component is used both on the product page and on the search page
// when there's only one result
class Product extends React.Component<PropType, StateType> {
  productDescriptionRef: React.RefObject<HTMLDivElement>;
  constructor(props: PropType) {
    super(props);
    this.state = {
      isPanelOpen: false,
      isExpandReviews: false,
      isWatchablePanelOpen: false,
      isFullscreenViewCarouselOpen: false,
      isColorPanelOpen: false,
    };
    this.productDescriptionRef = React.createRef();
  }

  toggleproductInfoPosition = (isOpen: boolean) => {
    this.setState({ isFullscreenViewCarouselOpen: isOpen });
  };

  scrollToDescription = async () => {
    if (this.productDescriptionRef) {
      const headerOffsetHeight = 130;
      const descriptionScrollDuration = 350;
      const totalOffset = document.body.getBoundingClientRect().top + headerOffsetHeight;
      const { top } = this.productDescriptionRef.current.getBoundingClientRect();

      if (this.props.currentBreakpoint >= Breakpoint.Medium) {
        let headerOffsetTop = 91;
        if (isIE()) {
          const legacyBrowserBannerWarningHeight = 24;
          headerOffsetTop = headerOffsetTop + legacyBrowserBannerWarningHeight;
        }
        if (window.pageYOffset <= headerOffsetTop) {
          const headerScrollDuration = 30;
          await scrollTo(headerOffsetTop, headerScrollDuration);
        }
        window.requestAnimationFrame(() => {
          if (!isIE()) {
            window.dispatchEvent(new Event('scroll'));
          } else {
            const event = document.createEvent('Event');
            event.initEvent('scroll', false, true);
            window.dispatchEvent(event);
          }
        });
      }
      await scrollTo(top - totalOffset, descriptionScrollDuration);
    }
  };

  openVariantPanel = () => this.setState({ isPanelOpen: true });
  closeVariantPanel = () => this.setState({ isPanelOpen: false });
  openWatchablePanel = () => this.setState({ isWatchablePanelOpen: true });
  closeWatchablePanel = () => this.setState({ isWatchablePanelOpen: false });

  toggleReviewPanel = () => {
    this.setState({
      isExpandReviews: !this.state.isExpandReviews,
    });
  };

  toggleColorSelect = () => {
    this.setState({
      isColorPanelOpen: !this.state.isColorPanelOpen,
    });
  };

  showMore = (fetchingDone: () => void) => {
    if (this.props.allReviews && this.props.totalReviews > this.props.allReviews.length) {
      this.props.getReviews(this.props.url, this.props.productCode, this.props.reviewsPage + 1).then(fetchingDone);
    }
  };

  componentDidMount() {
    this.props.initializeReviews(this.props.productCode, this.props.topReviews, 1, this.props.totalReviews);
  }

  addItemToCart = () => {
    if (this.props.displayTagDesigner && this.props.designTagProperties.text === '') {
      return null;
    }
    return this.props.addToCart(
      epiPropertyValue(this.props.variation.code),
      1,
      this.props.ticket,
      this.props.displayTagDesigner ? this.props.designTagProperties : null,
    );
  };

  addAllItemsToCart = (products: any[]) => {
    Promise.all(products.map((product) => this.props.addToCart(product.code, 1, product.ticket, null)));
  };

  render() {
    const {
      variation,
      price,
      ticket,
      variations = [],
      name,
      imageUrls = [],
      youTubeIds = [],
      vimeoIds = [],
      brand,
      brandUrl,
      description,
      bulletPoints,
      catalogId,
      language,
      productCode,
      averageRating,
      inStock,
      hasPromotion,
      promotionNames = [],
      productRecommendations = [],
      recentlyViewed = [],
      totalReviews,
      sizeGuide,
      hasMultipleVariants,
      sku,
      url,
      campaignCategoryPageUrl,
      productUspDelivery,
      productUspGuarantee,
      productUspReturn,
      productUspDeliveryVxn,
      productUspDeliveryVxnTitle,
      productUspDiscreetVxn,
      productUspDiscreetVxnTitle,
      iconExpressDelivery,
      iconDiscreet,
      imageContentBlock,
      gwpPromotionGift,
      displayTagDesigner,
      designTagColors,
      designTagFonts,
      productLinks = [],
      selfProductLink,
      contentBlocks,
      variantPromotions,
    } = this.props;
    const { isColorPanelOpen } = this.state;
    const productName = prepareVariationName(this.props.$cache === 'partial' ? variation.displayName : name);
    const variantName = this.props.$cache === 'partial' ? '-' : prepareVariationName(variation.displayName);
    const code = epiPropertyValue(variation.code);
    const reviews = {
      catalogId,
      language,
      productCode,
      averageRating,
      topReviews: this.props.topReviews || [],
      totalReviews,
      productPageUrl: url,
      isExpandReviews: this.state.isExpandReviews,
      toggleReviewPanel: this.toggleReviewPanel,
    };

    const IsVxnSite =
      !this.props.currentTheme.startsWith('dog') &&
      !this.props.currentTheme.startsWith('cli') &&
      !this.props.currentTheme.startsWith('lac') &&
      !this.props.currentTheme.startsWith('party');

    const watchableButtonProps = {
      buttonText: translate('/Product/WatchableProduct/WatchableButtonText'),
      onPanelOpen: this.openWatchablePanel,
    };

    const skuCode = {
      id: 'SKUCode',
      category: 'details',
      type: 'string',
      value: sku,
    };

    const quickFacts = this.props.quickFacts ? [...this.props.quickFacts, skuCode] : [skuCode];

    const CampaignNode = (
      <div>
        <Campaign>
          {translate('/Product/IncludedInCampaign') + ' :'}
          {!!variantPromotions &&
            variantPromotions.map((item, i) => (
              <Link to={!!item.promotionUrl ? item.promotionUrl : campaignCategoryPageUrl}>{item.promotionName}</Link>
            ))}
        </Campaign>
      </div>
    );

    const averageRatingsNode = (
      <AverageContainer>
        <AverageRating average={averageRating} spacing={Spacing.Normal} size={Size.Huge} />
        {!this.state.isFullscreenViewCarouselOpen && (
          <TotalReviews>
            {totalReviews > 0 ? (
              <ShowReviews onClick={this.toggleReviewPanel}>{`${totalReviews} ${translate(
                '/Product/Reviews/Heading',
              ).toLowerCase()}`}</ShowReviews>
            ) : (
              translate('/Product/Reviews/NoReviews')
            )}
          </TotalReviews>
        )}
      </AverageContainer>
    );

    const desktopDetails = (
      <React.Fragment>
        <Details
          css={{
            paddingBottom: pixelsToUnit(15),
            marginTop: '2rem',
            ...(this.state.isFullscreenViewCarouselOpen &&
              !this.state.isFullscreenViewCarouselOpen &&
              this.props.currentBreakpoint > Breakpoint.Large && {
                position: 'fixed',
                top: '3rem',
                right: '2.5rem',
                zIndex: 20,
                maxWidth: pixelsToUnit(480),
                backgroundColor: 'rgba(255,255,255,0.7)',
                backdropFilter: 'blur(5px)',
                boxShadow: (theme) => `0 0 1rem rgba(${theme.shadowColor}, 0.3)`,
                borderRadius: '0.5rem',
              }),
          }}
        >
          <BrandAndStockSection>
            {!this.state.isFullscreenViewCarouselOpen && (
              <LastBreadCrumbBrandContainer>
                <LastBreadcrumb breadcrumbs={this.props.breadcrumbs} isSliced />
                <Span>{'-'}</Span>
                <Brand brand={brand} brandUrl={brandUrl} />
              </LastBreadCrumbBrandContainer>
            )}
            {this.props.inStock && (
              <Stock>
                <CheckIcon />
                {translate('/Product/InStockForDelivery')}
              </Stock>
            )}
          </BrandAndStockSection>
          <ProductName name={productName} />
          {hasPromotion && CampaignNode}
          {averageRatingsNode}
          <Prices
            css={{
              display: 'flex',
              flex: { direction: 'column', grow: 1, wrap: 'wrap' },
              margin: {
                y: large,
                x: 0,
              },
            }}
            prices={price}
          />
          <UspBullets hasValue={bulletPoints && bulletPoints.length > 0} bulletPoints={bulletPoints} />
          {!this.state.isFullscreenViewCarouselOpen && (
            <DescriptionAndSizeGuide>
              {description && <ReadMore onClick={this.scrollToDescription}>{translate('/Product/ReadMore')}</ReadMore>}
              {sizeGuide && <SizeGuide sizeGuide={sizeGuide} />}
            </DescriptionAndSizeGuide>
          )}
          {productLinks && (
            <ProductLinksContainer>
              {productLinks[0].linkDisplayType === 'color' ? (
                <ProductLinksTitle>{translate('/Product/Colors')}:</ProductLinksTitle>
              ) : (
                <ProductLinksTitle>{translate('/Product/Variants')}:</ProductLinksTitle>
              )}
              <ProductLinksMenuWrapper>
                <ProductLinksMenu>
                  {productLinks[0].linkDisplayType === 'color' ? (
                    <ProductLinkItemWrapperCurrent title={selfProductLink.tooltip}>
                      <CurrentProductLink
                        css={{ backgroundColor: '#' + selfProductLink.linkDisplayValue }}
                      ></CurrentProductLink>
                    </ProductLinkItemWrapperCurrent>
                  ) : (
                    <ProductLinkItemWrapperCurrentThumb>
                      <img src={selfProductLink.linkDisplayValue} style={{ opacity: 0.5 }} />
                    </ProductLinkItemWrapperCurrentThumb>
                  )}
                  <ColorMenuButton onClick={this.toggleColorSelect}>
                    {isColorPanelOpen ? <UpStyled /> : <DownStyled />}
                  </ColorMenuButton>
                </ProductLinksMenu>
              </ProductLinksMenuWrapper>
              <ProductLinksColorWrapper css={isColorPanelOpen && { transform: 'scaleY(1)' }}>
                {productLinks &&
                  productLinks.map((item) => (
                    <ProductLinkWrapper>
                      {item.linkDisplayType === 'color' ? (
                        <ProductLinkItemWrapper title={item.tooltip}>
                          <ProductLinkItem to={item.url} css={{ backgroundColor: '#' + item.linkDisplayValue }} />
                        </ProductLinkItemWrapper>
                      ) : (
                        <ProductLinkItemThumb to={item.url}>
                          <img src={item.linkDisplayValue} />
                        </ProductLinkItemThumb>
                      )}
                      <ColorName>{item.tooltip}</ColorName>
                    </ProductLinkWrapper>
                  ))}
              </ProductLinksColorWrapper>
            </ProductLinksContainer>
          )}
          {hasMultipleVariants && (
            <VariantSelector
              label={translate('/Product/Size')}
              value={url}
              options={variations}
              onChangeOption={replaceState}
              onWatchableOpen={this.openWatchablePanel}
            />
          )}
          {displayTagDesigner && (
            <ProductDesignerConfigurator availableColors={designTagColors} availableFonts={designTagFonts} />
          )}
          {inStock ? (
            <AddCart addToCart={this.addItemToCart} variant={Variant.Medium}>
              <IconWrap>
                <IconCart />
                {this.props.currentTheme === Theme.DoggieDefault
                  ? translate('/Cart/BuyDoggie')
                  : translate('/Cart/BuyDesktop')}
              </IconWrap>
            </AddCart>
          ) : (
            <React.Fragment>
              <WatchableHelperInfo>{translate('/Product/WatchableProduct/WatchableHelperInfo')}</WatchableHelperInfo>
              <WatchableButton {...watchableButtonProps} />
            </React.Fragment>
          )}
          {IsVxnSite
            ? (productUspDeliveryVxn || productUspDiscreetVxn) && (
                <DetailsUspVxn>
                  {productUspDiscreetVxn && (
                    <UspContainer>
                      <ImageContainer>
                        <Image
                          src={iconDiscreet}
                          title={'discreet parcel'}
                          alt={'discreet parcel'}
                          ratio={Ratio.OneToOne}
                        />
                      </ImageContainer>
                      <styled.Div css={{ fontSize: zeta, textAlign: 'left' }}>
                        <b>{productUspDiscreetVxnTitle}</b>
                        {productUspDiscreetVxn}
                      </styled.Div>
                    </UspContainer>
                  )}
                  {productUspDeliveryVxn && (
                    <UspContainer>
                      <ImageContainer>
                        <Image
                          src={iconExpressDelivery}
                          title={'express delivery'}
                          alt={'express delivery'}
                          ratio={Ratio.OneToOne}
                        />
                      </ImageContainer>
                      <styled.Div css={{ fontSize: zeta, textAlign: 'left' }}>
                        <b>{productUspDeliveryVxnTitle}</b>
                        {productUspDeliveryVxn}
                      </styled.Div>
                    </UspContainer>
                  )}
                </DetailsUspVxn>
              )
            : (productUspDelivery || productUspReturn || productUspGuarantee) && (
                <DetailsUsp>
                  {productUspDelivery && (
                    <UspItem>
                      <CheckIcon />
                      {productUspDelivery}
                    </UspItem>
                  )}
                  {productUspReturn && (
                    <UspItem>
                      <CheckIcon />
                      {productUspReturn}
                    </UspItem>
                  )}
                  {productUspGuarantee && (
                    <UspItem>
                      <CheckIcon />
                      {productUspGuarantee}
                    </UspItem>
                  )}
                </DetailsUsp>
              )}

          <SameDayDelivery>{translate('/Product/SameDayDelivery')}</SameDayDelivery>
        </Details>
      </React.Fragment>
    );

    const mobileDetails = (
      <Details css={{ paddingTop: 0, paddingBottom: pixelsToUnit(10) }}>
        <BulletReviewWrapper>
          <UspBullets
            css={{ marginTop: '1rem', marginBottom: '1rem' }}
            hasValue={bulletPoints && bulletPoints.length > 0}
            bulletPoints={bulletPoints}
            isCompact
          />
          {averageRatingsNode}
        </BulletReviewWrapper>
        {sizeGuide && <SizeGuide sizeGuide={sizeGuide} />}
        {IsVxnSite
          ? (productUspDiscreetVxn || productUspDeliveryVxn) && (
              <DetailsUspVxn>
                {productUspDiscreetVxn && (
                  <UspContainer>
                    <ImageContainer>
                      <Image
                        src={iconDiscreet}
                        title={'discreet parcel'}
                        alt={'discreet parcel'}
                        ratio={Ratio.OneToOne}
                      />
                    </ImageContainer>
                    <styled.Div css={{ fontSize: zeta, textAlign: 'left' }}>
                      <b>{productUspDiscreetVxnTitle}</b>
                      {productUspDiscreetVxn}
                    </styled.Div>
                  </UspContainer>
                )}
                {productUspDeliveryVxn && (
                  <UspContainer>
                    <ImageContainer>
                      <Image
                        src={iconExpressDelivery}
                        title={'express delivery'}
                        alt={'express delivery'}
                        ratio={Ratio.OneToOne}
                      />
                    </ImageContainer>
                    <styled.Div css={{ fontSize: zeta, textAlign: 'left' }}>
                      <b>{productUspDeliveryVxnTitle}</b>
                      {productUspDeliveryVxn}
                    </styled.Div>
                  </UspContainer>
                )}
              </DetailsUspVxn>
            )
          : (productUspDelivery || productUspReturn || productUspGuarantee) && (
              <DetailsUsp>
                {productUspDelivery && (
                  <UspItem>
                    <CheckIcon />
                    <UspItemText>{productUspDelivery}</UspItemText>
                  </UspItem>
                )}
                {productUspReturn && (
                  <UspItem>
                    <CheckIcon />
                    <UspItemText>{productUspReturn}</UspItemText>
                  </UspItem>
                )}
                {productUspGuarantee && (
                  <UspItem>
                    <CheckIcon />
                    <UspItemText>{productUspGuarantee}</UspItemText>
                  </UspItem>
                )}
              </DetailsUsp>
            )}

        {inStock && !IsVxnSite && (
          <SameDayDelivery
            css={{
              marginTop: pixelsToUnit(5),
              fontSize: kappa,
            }}
          >
            {translate('/Product/SameDayDelivery')}kaka
          </SameDayDelivery>
        )}
      </Details>
    );

    const detailsDesktopStyle: Style = {
      maxWidth: pixelsToUnit(570),
      margin: {
        y: 0,
        x: 'auto',
      },
    };

    const detailsMobileStyle: Style = {
      textAlign: 'center',
    };

    const stockAndPromos: Style = {
      display: 'flex',
      flexDirection: 'column',
    };

    return (
      <Viewport>
        {(isCompact: boolean) => (
          <Base css={isCompact && { backgroundColor: white }} appearance={Appearance.Full}>
            <CustomBreadcrumbs breadcrumbs={this.props.breadcrumbs} />
            <Basic>
              <MarginContainer>
                <Wrapper>
                  <Basic layout={PageLayout.OneToOne}>
                    {isCompact ? (
                      <styled.Div css={detailsMobileStyle}>
                        <styled.Div css={stockAndPromos}>
                          {this.props.inStock ? (
                            <Stock>
                              <CheckIcon />
                              {translate('/Product/InStockForDelivery')}
                            </Stock>
                          ) : (
                            <Stock>
                              <Cross css={{ color: 'red', paddingTop: '0.2rem' }} />
                              {translate('/Product/OutOfStock')}
                            </Stock>
                          )}
                          {hasPromotion && CampaignNode}
                        </styled.Div>
                        {!displayTagDesigner && price.discountPercentage > 0 && (
                          <>
                            {IsVxnSite ? (
                              <DiscountTriBadge>{'-' + price.discountPercentage + '%'}</DiscountTriBadge>
                            ) : (
                              <PercentDiscountBadge>{'-' + price.discountPercentage + '%'}</PercentDiscountBadge>
                            )}
                          </>
                        )}
                        <styled.Div css={{ paddingTop: pixelsToUnit(10) }}>
                          {!this.state.isFullscreenViewCarouselOpen && (
                            <LastBreadCrumbBrandContainer
                              css={{ justifyContent: 'center', fontSize: zeta, color: (theme) => theme.textColor }}
                            >
                              <LastBreadcrumb
                                css={{ color: (theme) => theme.textColor }}
                                breadcrumbs={this.props.breadcrumbs}
                                isSliced
                              />
                              <Span>{'-'}</Span>
                              <Brand brand={brand} brandUrl={brandUrl} />
                            </LastBreadCrumbBrandContainer>
                          )}

                          <styled.Div css={{ paddingRight: pixelsToUnit(35), paddingLeft: pixelsToUnit(35) }}>
                            {productName.length > 30 ? (
                              <ProductNameSmall>{productName}</ProductNameSmall>
                            ) : (
                              <ProductName name={productName} />
                            )}
                          </styled.Div>
                        </styled.Div>
                        {(!!imageUrls.length || !!youTubeIds.length || !!vimeoIds.length) && (
                          <Base>
                            <ProductMedia
                              onModalToggle={this.toggleproductInfoPosition}
                              imageUrls={imageUrls}
                              youTubeIds={youTubeIds}
                              vimeoIds={vimeoIds}
                              alt={name}
                              isCompact={isCompact}
                            />
                            {displayTagDesigner && <ProductDesigner />}
                            {displayTagDesigner && (
                              <ProductDesignerConfigurator
                                availableColors={designTagColors}
                                availableFonts={designTagFonts}
                              />
                            )}
                            {!!gwpPromotionGift && (
                              <Wrapper>
                                <GwpFreeItem gwpFreePromotion={gwpPromotionGift} />
                              </Wrapper>
                            )}
                          </Base>
                        )}
                      </styled.Div>
                    ) : (
                      (!!imageUrls.length || !!youTubeIds.length || !!vimeoIds.length) && (
                        <Base>
                          {!displayTagDesigner && price.discountPercentage > 0 && (
                            <>
                              {IsVxnSite ? (
                                <DiscountTriBadge>{'-' + price.discountPercentage + '%'}</DiscountTriBadge>
                              ) : (
                                <PercentDiscountBadge>{'-' + price.discountPercentage + '%'}</PercentDiscountBadge>
                              )}
                            </>
                          )}
                          {displayTagDesigner && <ProductDesigner />}
                          <ProductMedia
                            onModalToggle={this.toggleproductInfoPosition}
                            imageUrls={imageUrls}
                            youTubeIds={youTubeIds}
                            vimeoIds={vimeoIds}
                            alt={name}
                          />
                          {!!gwpPromotionGift && (
                            <Wrapper>
                              <GwpFreeItem gwpFreePromotion={gwpPromotionGift} />
                            </Wrapper>
                          )}
                        </Base>
                      )
                    )}
                    <styled.Div css={isCompact ? detailsMobileStyle : detailsDesktopStyle}>
                      {isCompact ? mobileDetails : desktopDetails}
                    </styled.Div>
                  </Basic>
                </Wrapper>
                <ContentArea {...imageContentBlock} />
                <ProductDescriptionSectionWrapper>
                  <ProductDescriptionSection
                    ref={this.productDescriptionRef}
                    description={description}
                    reviews={reviews}
                    quickFacts={quickFacts}
                    isCompact={isCompact}
                  />
                </ProductDescriptionSectionWrapper>
                <ContentArea {...contentBlocks} />
                <ReviewSection reviews={reviews} isCompact={isCompact} />
              </MarginContainer>
              {this.props.commonPurchaseCombinationList && this.props.commonPurchaseCombinationList.length > 0 && (
                <React.Fragment>
                  <Header>{translate('/Product/RecommendedProducts')}</Header>
                  <CommonPurchaseCombination
                    isCompact={isCompact}
                    commonPurchaseCombinationList={this.props.commonPurchaseCombinationList}
                    addAllItemsToCart={this.addAllItemsToCart}
                  />
                </React.Fragment>
              )}
              {productRecommendations.length > 0 && <ProductRelated relatedProducts={productRecommendations} />}
              {!isCompact && recentlyViewed.length > 0 && <ProductRecentlyViewed recentlyViewed={recentlyViewed} />}
            </Basic>
            {isCompact && code && price && (
              <Actionbar>
                <ProductActionbar
                  price={price}
                  productLinks={productLinks}
                  selfProductLink={selfProductLink}
                  variantName={hasMultipleVariants ? variantName : undefined}
                  onVariantPanelOpen={hasMultipleVariants ? this.openVariantPanel : undefined}
                  onWatchablePanelOpen={this.openWatchablePanel}
                  code={code}
                  ticket={ticket}
                  inStock={inStock}
                  addToCart={this.addItemToCart}
                  promotionName={hasPromotion && promotionNames.length ? promotionNames[0] : ''}
                  campaignCategoryPageUrl={campaignCategoryPageUrl}
                />
              </Actionbar>
            )}
            {isCompact && hasMultipleVariants && (
              <VariantPanel
                open={this.state.isPanelOpen}
                value={{
                  name: variantName,
                  code,
                }}
                options={variations}
                onPanelClose={this.closeVariantPanel}
                sizeguide={sizeGuide}
                onWatchableOpen={this.openWatchablePanel}
              />
            )}
            <ViewAllReviews
              list={
                this.props.productReviewId === this.props.productCode && this.props.allReviews
                  ? this.props.allReviews
                  : reviews.topReviews
              }
              isExpandReviews={this.state.isExpandReviews}
              closeReviews={this.toggleReviewPanel}
              showMore={this.showMore}
              averageRating={averageRating}
              isCompact={isCompact}
              totalReview={this.props.totalReviews}
              isModalList={true}
            />
            <WatchablePanel
              pageUrl={this.props.canonicalUrl}
              variationCode={code}
              open={this.state.isWatchablePanelOpen}
              onPanelClose={this.closeWatchablePanel}
            />
          </Base>
        )}
      </Viewport>
    );
  }
}

export default connect(
  (state): ConnectStateType => ({
    currentTheme: state.currentTheme,
    currentBreakpoint: state.currentBreakpoint,
    allReviews: state.productReview.allReviews,
    reviewsPage: state.productReview.page,
    productReviewId: state.productReview.id,
    designTagProperties: state.productDesigner,
  }),
  (dispatch): ConnectActionType => ({
    addToCart(code, quantity, ticket, designTagProperties) {
      return dispatch(addToCart(code, quantity, ticket, 'Produktlista', false, designTagProperties));
    },
    getReviews(url, id, page) {
      return dispatch(getReviews(url, id, page));
    },
    initializeReviews(id, reviews, page, totalCount) {
      return dispatch(initializeReviews(id, reviews, page, totalCount));
    },
  }),
)(Product);
