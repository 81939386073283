import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import connect from 'Shared/connect';
import {
  minMicroMediaQuery,
  minSmallMediaQuery,
  minMediumMediaQuery,
  minHugeMediaQuery,
  minLargeMediaQuery,
  pixelsToUnit,
} from 'Shared/Style';
import { getVideoUrl } from 'Shared/Blocks/Video/VideoBlock';
import { ensureYTInit } from 'Shared/Video/video-helper';
import YoutubePlayerType, { YouTubeAPI, YoutubeStateTypes } from 'Shared/Video/youtube.ts';

type ConnectStateType = {
  currentBreakpoint: number;
};

type PropTypes = StyledProps &
  ConnectStateType & {
    key?: string;
    index: number;
    videoId: string;
    options?: string;
    isCurrent: boolean;
  };
type StateTypes = {
  playerIsReady: boolean;
};
class ProductMedia extends React.Component<PropTypes, StateTypes> {
  player: YoutubePlayerType;
  YT: YouTubeAPI;
  constructor(props: PropTypes) {
    super(props);
    this.state = { playerIsReady: false };
  }

  async componentDidMount() {
    this.YT = await ensureYTInit();
    this.initPlayer();
  }

  initPlayer = () => {
    this.player = new this.YT.Player(`youTube-video-${this.props.index}`, {
      events: {
        onReady: this.onPlayerReady,
      },
    });
  };
  onPlayerReady = () => {
    if (this.props.currentBreakpoint < 4) {
      this.player.mute();
    }
    this.setState({ playerIsReady: true });
  };
  componentDidUpdate(prevProps: PropTypes) {
    if (prevProps.isCurrent && !this.props.isCurrent && this.state.playerIsReady) {
      this.player.pauseVideo();
    }
  }

  toggleVideo = () => {
    const currentState = this.player.getPlayerState();
    if (
      currentState === YoutubeStateTypes.UNSTARTED ||
      currentState === YoutubeStateTypes.PAUSED ||
      currentState === YoutubeStateTypes.ENDED ||
      currentState === YoutubeStateTypes.CUED
    ) {
      this.player.playVideo();
    } else {
      this.player.pauseVideo();
    }
  };

  render() {
    const isMobile = this.props.currentBreakpoint < 4;
    const { index, videoId } = this.props;
    return (
      <VideoContainer>
        <LayerWrapper>
          <VideoIframe
            id={`youTube-video-${index}`}
            frameBorder="0"
            allowFullScreen
            allow="autoplay"
            src={getVideoUrl(videoId, 'youTube') + this.props.options}
            loading="lazy"
          />
          {isMobile && <Layer onClick={this.toggleVideo} />}
        </LayerWrapper>
      </VideoContainer>
    );
  }
}
export default styled(
  connect((state) => ({
    currentBreakpoint: state.currentBreakpoint,
  }))(ProductMedia),
);

const Layer = styled.div({
  position: 'absolute',
  left: 0,
  width: '80%',
  height: '40%',
  top: '25%',
  zIndex: 1,
});
const LayerWrapper = styled.div({
  width: '100%',
  position: 'relative',
});
const VideoContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

const VideoIframe = styled.iframe({
  height: '180px',
  width: '100%',
  [minHugeMediaQuery]: {
    height: pixelsToUnit(400),
  },
  [minLargeMediaQuery]: {
    height: pixelsToUnit(350),
  },
  [minMediumMediaQuery]: {
    height: pixelsToUnit(250),
  },
  [minSmallMediaQuery]: {
    height: pixelsToUnit(400),
  },
  [minMicroMediaQuery]: {
    height: pixelsToUnit(220),
  },
});
