import React from 'react';
import { styled } from '@glitz/react';
import { pseudo } from '@glitz/core';
import { large, minMediumMediaQuery, pixelsToUnit } from 'Shared/Style';
import { Star, StarOutline } from 'Shared/Icon/Star';
import Stars from 'Product/Reviews/Ratings/ReadOnly';

type PropType = {
  rating: number;
  totalReviews: number;
};

const StyledStar = styled(Star, {
  width: pixelsToUnit(20),
  height: pixelsToUnit(19),
  ...pseudo(':not(:first-child)', {
    marginLeft: pixelsToUnit(10),
  }),
  [minMediumMediaQuery]: {
    ':not(:first-child)': {
      marginLeft: pixelsToUnit(5),
    },
  },
});

const StyledStarOutline = styled(StarOutline, {
  width: pixelsToUnit(20),
  height: pixelsToUnit(19),
  ...pseudo(':not(:first-child)', {
    marginLeft: pixelsToUnit(15),
  }),
  [minMediumMediaQuery]: {
    ':not(:first-child)': {
      marginLeft: pixelsToUnit(5),
    },
  },
});

const Ratings = (props: PropType) => {
  return typeof props.rating !== 'undefined' || props.rating !== null ? (
    <styled.Div
      css={{
        margin: {
          y: large,
          x: 0,
        },
      }}
    >
      <Stars
        css={{ display: 'inline-block' }}
        rating={props.rating}
        star={StyledStar}
        starOutline={StyledStarOutline}
      />
    </styled.Div>
  ) : null;
};

export default Ratings;
