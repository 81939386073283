import React, { useState } from 'react';
import { styled } from '@glitz/react';
import ReviewViewModelType from 'Reviews/Models/ReviewViewModel.type';
import { pixelsToUnit, minMediumMediaQuery, silverchalice, transition, white } from 'Shared/Style';
import Box from './Box';
import { translate } from '@avensia/scope';

type PropType = {
  list: ReviewViewModelType[];
  isReviewModal?: boolean;
};

export default (props: PropType) => {
  const [dateSortedList, setDateSortedList] = useState(props.list);
  const [latestFirst, setLatestFirst] = useState(true);
  const [highestFirst, setHighestFirst] = useState(true);

  function toggleSort() {
    const sortedList = props.list.slice().sort((a, b) => {
      return new Date(a.publishDate).getTime() - new Date(b.publishDate).getTime();
    });
    if (latestFirst) {
      setDateSortedList(sortedList);
      setLatestFirst(!latestFirst);
    } else if (!latestFirst) {
      setDateSortedList(sortedList.reverse());
      setLatestFirst(!latestFirst);
    }
  }

  function toggleSortRating() {
    const sortedRatings = props.list.slice().sort((a, b) => {
      return a.rating - b.rating;
    });
    if (highestFirst) {
      setDateSortedList(sortedRatings.reverse());
      setHighestFirst(!highestFirst);
    } else if (!highestFirst) {
      setDateSortedList(sortedRatings);
      setHighestFirst(!highestFirst);
    }
  }

  return props.list && props.list.length > 0 ? (
    <Base>
      {props.list.length > 4 ? (
        <SortContainer>
          <SortOptions>
            <SortLabel>{translate('/Product/Reviews/Sort')}</SortLabel>
            <SortDate onClick={toggleSort}>{translate('/Product/Reviews/Date')}</SortDate>
            <SortRating onClick={toggleSortRating}>{translate('/Product/Reviews/Rating')}</SortRating>
          </SortOptions>
        </SortContainer>
      ) : (
        <div />
      )}
      {dateSortedList.map((review) => (
        <>
          <Box key={review.publishDate} review={review} isModal={props.isReviewModal} />
        </>
      ))}
    </Base>
  ) : null;
};

const Base = styled.div({
  height: 'auto',
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  justifyContent: 'space-between',
  marginTop: pixelsToUnit(10),
  [minMediumMediaQuery]: {
    marginTop: pixelsToUnit(10),
  },
});

const SortContainer = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
});

const SortOptions = styled.div({
  display: 'flex',
  borderRadius: '17px',
  border: {
    xy: {
      width: '1px',
      style: 'solid',
      color: silverchalice,
    },
  },
});

const SortButton = styled.div({
  padding: { x: pixelsToUnit(20), y: pixelsToUnit(5) },
  backgroundColor: white,
  cursor: 'pointer',
  ...transition({ property: 'background-color', duration: '0.5s' }),
  ':hover': { backgroundColor: silverchalice },
});

const SortDate = styled(SortButton, {});

const SortRating = styled(SortButton, {
  borderRadius: '0 15px 15px 0',
});

const SortLabel = styled.div({
  backgroundColor: silverchalice,
  color: white,
  borderRadius: '15px 0 0 15px',
  padding: { x: pixelsToUnit(20), y: pixelsToUnit(5) },
});
