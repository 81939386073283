import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { translate, Breakpoint } from '@avensia/scope';
import { minMediumMediaQuery, thin, pixelsToUnit, delta, large, gamma, medium, black } from 'Shared/Style';
import Panel from 'Shared/Panel';
import connect from 'Shared/connect';
import ReviewViewModelType from 'Reviews/Models/ReviewViewModel.type';
import Content from './Modal';
import { openModal, closeModal } from './action-creators';
import ReviewBoxList from './List';
import AddReviewButton from './AddReviewButton';

type ConnectStateType = {
  isOpen: boolean;
  currentBreakpoint?: number;
};

type ConnectActionType = {
  openModal: () => any;
  closeModal: () => any;
};

type PropType = ConnectStateType &
  ConnectActionType & {
    catalogId: number;
    language: string;
    productCode: string;
    averageRating: number;
    topReviews: ReviewViewModelType[];
    totalReviews: number;
    productPageUrl?: string;
    toggleReviewPanel: () => void;
  };

const Reviews = (props: PropType & StyledProps) => {
  const isMobile = props.currentBreakpoint < Breakpoint.Medium;

  return (
    <styled.Div css={props.compose()}>
      <HeaderContainer>
        <H2>
          {translate('/Product/Reviews/Heading')}
          <TotalReviews>{props.totalReviews}</TotalReviews>
        </H2>
        <ShowAllContainer>
          {props.totalReviews > props.topReviews.length && (
            <ShowAll onClick={props.toggleReviewPanel}>
              {isMobile
                ? `${translate('/Product/Reviews/ShowAll')} (${props.totalReviews})`
                : translate('/Product/Reviews/ShowAll')}
            </ShowAll>
          )}
        </ShowAllContainer>
      </HeaderContainer>
      <ReviewBoxList list={props.topReviews} isReviewModal={false} />
      <AddReviewButton onClick={props.openModal} />

      <Panel isOpen={props.isOpen} maxHeight={MODAL_HEIGHT} maxWidth={MODAL_WIDTH} onClose={props.closeModal}>
        <Content
          catalogId={props.catalogId}
          language={props.language}
          productCode={props.productCode}
          close={props.closeModal}
        />
      </Panel>
    </styled.Div>
  );
};

export default styled(
  connect(
    (state): ConnectStateType => ({
      isOpen: state.productReview.isOpen,
      currentBreakpoint: state.currentBreakpoint,
    }),
    (dispatch): ConnectActionType => ({
      openModal: () => dispatch(openModal()),
      closeModal: () => dispatch(closeModal()),
    }),
  )(Reviews),
);

const MODAL_HEIGHT = pixelsToUnit(748);
const MODAL_WIDTH = pixelsToUnit(657);
const H2 = styled.h2({
  fontSize: delta,
  marginBottom: large,
  letterSpacing: pixelsToUnit(2),
  lineHeight: 'normal',
  textAlign: 'center',
  textTransform: 'uppercase',
  [minMediumMediaQuery]: {
    fontSize: gamma,
    marginBottom: pixelsToUnit(20),
    marginTop: pixelsToUnit(20),
    letterSpacing: pixelsToUnit(2.3),
  },
});

const TotalReviews = styled.span({
  ':before': {
    content: "'('",
  },
  ':after': {
    content: "')'",
  },
  marginLeft: medium,
});

const ShowAll = styled.span({
  borderBottom: {
    width: thin,
    style: 'solid',
    color: black,
  },
  marginBottom: pixelsToUnit(20),
  cursor: 'pointer',
  [minMediumMediaQuery]: {
    position: 'absolute',
    top: 0,
    right: 0,
    marginTop: pixelsToUnit(20),
  },
});

const HeaderContainer = styled.div({
  position: 'relative',
  width: '100%',
  [minMediumMediaQuery]: {},
});

const ShowAllContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
});
