import React, { useState, useLayoutEffect, useRef } from 'react';
import { styled } from '@glitz/react';
import { translate } from '@avensia/scope';
import PriceViewModelType from 'Pricing/PriceViewModel.type';
import { Prices } from 'Product/ProductDetails';
import AddToCart from 'Product/ProductAddToCart';
import { MobileSelectorActionbar as VariantSelector } from 'Product/ProductDetails/VariantSelector';
import { Variant } from 'Shared/Button';
import { lightSilver, pixelsToUnit, kappa, thin, animation, minTinyMediaQuery, white, thick } from 'Shared/Style';
import Link from 'Shared/Link';
import { WatchableButtonActionbar } from '../WatchableProduct';
import { StyleOrStyleArray } from '@glitz/type';
import ProductLinkViewModel from 'Product/ProductLinkViewModel.type';
import { slideUp, slideDown } from '../keyframes.scss';
import CloseIcon from 'Shared/Icon/Close';

type PropType = {
  code: string;
  ticket: string;
  inStock: boolean;
  price: PriceViewModelType;
  productLinks: ProductLinkViewModel[];
  selfProductLink: ProductLinkViewModel;
  variantName: string | undefined;
  promotionName: string;
  campaignCategoryPageUrl: string;
  onVariantPanelOpen: () => void | undefined;
  onWatchablePanelOpen: () => void | undefined;
  addToCart: () => Promise<void>;
};

const Base = styled.div({
  backgroundColor: (theme) => theme.productMobileActionbar,
  padding: { x: 0, y: pixelsToUnit(10) },
  display: 'flex',
  justifyContent: 'center',
  width: '100vw',
  borderTop: {
    style: 'solid',
    color: lightSilver,
    width: thin,
  },
});

const Wrapper = styled.div({
  width: '100%',
  maxWidth: '40rem',
  display: 'flex',
  justifyContent: 'space-between',
  padding: {
    x: '1rem',
    y: 0,
  },
});

const ProductPrices = styled(Prices, {
  flexDirection: 'column',
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'flex-start',
});

const ProductLinksMenu = styled.div({
  position: 'absolute',
  left: 0,
  opacity: 0,
  width: '100%',
  zIndex: -2,
  backgroundColor: (theme) => theme.productMobileActionbar,
  paddingBottom: '2rem',
});

const ProductLinkItemContainer = styled.div({
  display: 'flex',
  justifyContent: 'left',
  marginTop: '0.3rem',
});

const ProductLinksMenuTopBar = styled.div({
  height: '2rem',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'white',
  border: {
    y: {
      width: thin,
      style: 'solid',
      color: 'lightgray',
    },
  },
});

const ProductLinksMenuTitle = styled.div({
  display: 'flex',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  lineHeight: '2rem',
});

const ProductLinkItemWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '3.5rem',
  height: '3.5rem',
  marginTop: '0.2rem',
  backgroundColor: 'white',
  borderRadius: pixelsToUnit(5),
  border: {
    xy: {
      width: thin,
      style: 'solid',
      color: lightSilver,
    },
  },
});

const ProductLinkItem = styled(Link, {
  width: '3.4rem',
  height: '3.4rem',
  borderRadius: pixelsToUnit(5),
  border: {
    xy: {
      width: thin,
      style: 'solid',
      color: lightSilver,
    },
  },
});

const ProductLinkItemThumb = styled(ProductLinkItem, {
  borderRadius: 0,
  width: '3.5rem',
  height: '3.5rem',
  margin: { xy: '0.2rem' },
});

export default function ProductActionbar(props: PropType) {
  const watchableButtonProps = {
    buttonText: translate('/Product/WatchableProduct/WatchableButtonTextMobile'),
    onPanelOpen: props.onWatchablePanelOpen,
  };

  const longPrice = props.price.current.toString().length > 3;

  const longVariantName = props.variantName && props.variantName.length > 3;

  const [isProductLinkPanelOpen, setIsProductLinkPanelOpen] = useState(false);
  const mobileActionButtonStyle: StyleOrStyleArray = {
    backgroundColor: (theme) => theme.buyButtonProductPageMobile,
    color: (theme) => theme.buyButtonProductPageMobileText,
    maxWidth: '30vw',
    height: '2.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    fontWeight: 800,
    fontSize: longVariantName ? pixelsToUnit(20) : pixelsToUnit(25),
  };
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useLayoutEffect(() => {
    if (!!props.productLinks) {
      setHeight(ref.current.clientHeight);
    }
  }, []);

  const ProductLinksMenuUp: StyleOrStyleArray = {
    ...animation({
      name: slideUp,
      duration: '0.4s',
      fillMode: 'both',
    }),
    top: -height - 35,
  };

  const ProductLinksMenuDown: StyleOrStyleArray = {
    ...animation({
      name: slideDown,
      duration: '0.4s',
      fillMode: 'both',
    }),
    top: -height - 35,
  };

  function toggleColorPanel() {
    setIsProductLinkPanelOpen(!isProductLinkPanelOpen);
  }

  return (
    <Base>
      {props.productLinks && (
        <ProductLinksMenu css={isProductLinkPanelOpen ? ProductLinksMenuUp : ProductLinksMenuDown}>
          <ProductLinksMenuTopBar>
            <styled.Div css={centerContentStyle}>
              {props.productLinks[0].linkDisplayType === 'color' ? (
                <ProductLinksMenuTitle css={{ width: '100%', justifyContent: 'space-between' }}>
                  {translate('/Product/Colors')}
                  <div onClick={toggleColorPanel}>
                    <CloseIcon />
                  </div>
                </ProductLinksMenuTitle>
              ) : (
                <ProductLinksMenuTitle>{translate('/Product/Variants')}</ProductLinksMenuTitle>
              )}
            </styled.Div>
          </ProductLinksMenuTopBar>
          <styled.Div css={{ width: '100%', display: 'flex', justifyContent: 'center' }} ref={ref}>
            <ProductLinkItemContainer css={leftContentStyle}>
              {props.productLinks.map((item: ProductLinkViewModel) => (
                <>
                  {item.linkDisplayType === 'color' ? (
                    <div
                      style={{
                        width: pixelsToUnit(80),
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <ProductLinkItemWrapper>
                        <ProductLinkItem
                          key={item.url}
                          to={item.url}
                          css={{ backgroundColor: '#' + item.linkDisplayValue }}
                        ></ProductLinkItem>
                      </ProductLinkItemWrapper>
                      <div style={{ color: white }}>{item.tooltip}</div>
                    </div>
                  ) : (
                    <ProductLinkItemThumb key={item.url} to={item.url}>
                      <ThumbImage src={item.linkDisplayValue} />
                    </ProductLinkItemThumb>
                  )}
                </>
              ))}
            </ProductLinkItemContainer>
          </styled.Div>
        </ProductLinksMenu>
      )}
      <Wrapper>
        <TextSection>
          <>{!props.inStock && <OutOfStockText>{translate('/Product/OutOfStock')}</OutOfStockText>}</>
          <ProductPrices prices={props.price} longPrice={longPrice} isCompact productActionbarMobile />
        </TextSection>
        <ButtonSection>
          {props.productLinks && (
            <LinkMenuButton onClick={toggleColorPanel}>
              {props.productLinks[0].linkDisplayType === 'color' ? (
                <CenterColor css={{ backgroundColor: '#' + props.selfProductLink.linkDisplayValue }}></CenterColor>
              ) : (
                <CurrentLinkThumb src={props.selfProductLink.linkDisplayValue}></CurrentLinkThumb>
              )}
            </LinkMenuButton>
          )}
          {props.variantName && (
            <VariantSelector
              longVariantName={longVariantName}
              value={props.variantName}
              onPanelOpen={props.onVariantPanelOpen}
            />
          )}
          {props.inStock ? (
            <AddCart
              css={mobileActionButtonStyle}
              variant={Variant.Medium}
              disabled={!props.inStock}
              addToCart={props.addToCart}
            >
              {translate('/Product/BuyActionbarMobile')}
            </AddCart>
          ) : (
            <WatchableButtonActionbar {...watchableButtonProps}>
              {translate('/Product/WatchableProduct/WatchableButtonText')}
            </WatchableButtonActionbar>
          )}
        </ButtonSection>
      </Wrapper>
    </Base>
  );
}

const centerContentStyle: StyleOrStyleArray = {
  maxWidth: '40rem',
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  padding: { x: '1rem', y: 0 },
  backgroundColor: (theme) => theme.productMobileActionbar,
  color: white,
};

const leftContentStyle: StyleOrStyleArray = {
  maxWidth: '40rem',
  display: 'flex',
  width: '100%',
  padding: { x: '0.7rem', y: 0 },
  flexWrap: 'wrap',
  justifyContent: 'center',
};

const TextSection = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
});

const ButtonSection = styled.div({
  display: 'flex',
  width: '50vw',
  justifyContent: 'flex-end',
});

const LinkMenuButtonBase = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const LinkMenuButton = styled(LinkMenuButtonBase, {
  width: '2.5rem',
  marginRight: pixelsToUnit(5),
  borderRadius: pixelsToUnit(5),
  border: {
    xy: {
      width: thick,
      style: 'solid',
      color: white,
    },
  },
  [minTinyMediaQuery]: {
    marginRight: pixelsToUnit(20),
  },
});

const CenterColor = styled(LinkMenuButtonBase, {
  height: '100%',
  width: '100%',
  borderRadius: pixelsToUnit(3),
});

const CurrentLinkThumb = styled.img({
  height: '2rem',
  width: '2rem',
  borderRadius: '100%',
  border: {
    xy: {
      width: thin,
      style: 'solid',
      color: lightSilver,
    },
  },
});

const ThumbImage = styled.img({});

const AddCart = styled(AddToCart, {
  maxWidth: '30vw',
  display: 'flex',
  justifyContent: 'center',
  flex: {
    grow: 1,
    basis: 1,
  },
});

const OutOfStockText = styled.span({
  display: 'flex',
  alignItems: 'center',
  fontSize: kappa,
  letterSpacing: pixelsToUnit(0.3),
  lineHeight: pixelsToUnit(16),
  color: white,
});
