import React from 'react';
import connect from 'Shared/connect';
import { Breakpoint } from '@avensia/scope';
import Image, { Preset as ImagePreset } from 'Shared/Image';
import { styled } from '@glitz/react';
import { getTheme } from 'Shared/Theming';
import { Theme as ColorTheme } from '@glitz/type';
import { CommonCarousel } from 'Shared/Carousel';
import Modal from 'Shared/Modal';
import { minMediumMediaQuery } from 'Shared/Style';
import YoutubeVideo from './youtubeVideo';
import VimeoVideo from './vimeoVideo';
import MobileModal from './MobileModal';

type ConnectedPropType = {
  cloakedImages: boolean;
  currentBreakpoint: number;
  theme: ColorTheme;
};

type ProductMediaPropType = {
  alt?: string;
  imageUrls?: string[];
  youTubeIds?: string[];
  vimeoIds?: string[];
  isCompact?: boolean;
  onModalToggle: (isOpen: boolean) => void;
};

type PropType = ProductMediaPropType & ConnectedPropType;

type StateType = {
  currentIndex: number;
  modalOpen: boolean;
};

type ItemTotals = {
  totalImages: number;
  totalYoutube: number;
  totalVimeo: number;
};

class ProductMedia extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      currentIndex: INITIAL_INDEX,
      modalOpen: false,
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyPress);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress);
  }

  navigate(newIndex: number) {
    this.setState({
      currentIndex: newIndex,
    });
  }

  openModal() {
    const isMobile = this.props.currentBreakpoint < 4;
    if (!isMobile) {
      this.props.onModalToggle(true);
      this.setState({
        modalOpen: true,
      });
    }
  }

  closeModal() {
    this.props.onModalToggle(false);
    this.setState({
      modalOpen: false,
    });
  }

  handleKeyPress = (e: KeyboardEvent) => {
    if (e.keyCode === 27) {
      this.closeModal();
    }
  };

  itemTotals = (): ItemTotals => {
    const { imageUrls, youTubeIds, vimeoIds } = this.props;
    const { length: totalImages } = imageUrls || [];
    const { length: totalYoutube } = youTubeIds || [];
    const { length: totalVimeo } = vimeoIds || [];
    return {
      totalImages,
      totalYoutube,
      totalVimeo,
    };
  };

  renderCarousel() {
    const { imageUrls = [], youTubeIds = [], vimeoIds = [] } = this.props;
    const isMobile = this.props.currentBreakpoint < 4;
    const imageHeight = isMobile ? '22rem' : '550px';

    return (
      <Base>
        {!this.state.modalOpen && (
          <CommonCarousel
            autoSlideTime={0}
            continuous
            height={imageHeight}
            currentSlide={this.state.currentIndex}
            onSlideChange={(index: number) => {
              this.setState({ currentIndex: index });
            }}
          >
            {youTubeIds.map((youTubeId, index) => (
              <YoutubeVideo
                key={`${index}-youTube`}
                index={index}
                videoId={youTubeId}
                options="?rel=0&loop=1&enablejsapi=1&fs=0"
                isCurrent={index === this.state.currentIndex}
              />
            ))}
            {vimeoIds.map((vimeoId, index) => (
              <VimeoVideo
                key={`${index}-vimeo`}
                index={youTubeIds.length + index}
                videoId={vimeoId}
                options="?autoplay=0"
                isCurrent={youTubeIds.length + index === this.state.currentIndex}
              />
            ))}
            {imageUrls.map((imageUrl) => (
              <div key={imageUrl} onClick={() => this.openModal()} style={{ cursor: 'zoom-in', height: '22rem' }}>
                {this.imageAsset(imageUrl, undefined, imageHeight)}
              </div>
            ))}
          </CommonCarousel>
        )}
      </Base>
    );
  }

  renderModal() {
    const { imageUrls = [], youTubeIds = [], vimeoIds = [] } = this.props;

    return (
      <React.Fragment>
        <Modal close={() => this.closeModal()}>
          <CommonCarousel
            height="100vh"
            autoSlideTime={0}
            continuous
            currentSlide={this.state.currentIndex}
            onSlideChange={(index: number) => {
              this.setState({ currentIndex: index });
            }}
          >
            {youTubeIds.map((youTubeId, index) => (
              <YoutubeVideo
                key={`${index}-youTube`}
                index={index}
                videoId={youTubeId}
                options="?rel=0&loop=1&enablejsapi=1&fs=0"
                isCurrent={index === this.state.currentIndex}
              />
            ))}
            {vimeoIds.map((vimeoId, index) => (
              <VimeoVideo
                key={`${index}-vimeo`}
                index={youTubeIds.length + index}
                videoId={vimeoId}
                options="?autoplay=0"
                isCurrent={youTubeIds.length + index === this.state.currentIndex}
              />
            ))}
            {imageUrls.map((imageUrl) => (
              <div key={imageUrl} style={{ height: '100%', display: 'flex', justifyContent: 'space-around' }}>
                {this.imageAsset(imageUrl, this.props.currentBreakpoint)}
              </div>
            ))}
          </CommonCarousel>
        </Modal>
      </React.Fragment>
    );
  }

  renderMobileModal() {
    const imageUrl = this.props.imageUrls[this.state.currentIndex];
    return <MobileModal key={this.state.currentIndex} imageUrl={imageUrl} cloakedImages={this.props.cloakedImages} />;
  }

  renderSingleAsset() {
    if (this.props.youTubeIds.length > 0) {
      return <YoutubeVideo index={0} videoId={this.props.youTubeIds[0]} options="?enablejsapi=1" isCurrent />;
    } else if (this.props.vimeoIds.length > 0) {
      return <VimeoVideo index={0} videoId={this.props.vimeoIds[0]} options="?autoplay=0" isCurrent />;
    } else if (this.props.imageUrls.length > 0) {
      return this.imageAsset();
    }
    return null;
  }

  imageAsset(imgSrc?: string, preset?: ImagePreset, imageHeight?: string) {
    const {
      alt,
      imageUrls: [firstImageUrl],
    } = this.props;
    return (
      <Image
        alt={alt}
        preset={!firstImageUrl.includes('.gif') ? preset || ImagePreset.Small : undefined}
        src={!!imgSrc ? imgSrc : firstImageUrl}
        css={{
          width: '100%',
          height: imageHeight,
          objectFit: 'contain',
        }}
      />
    );
  }

  render() {
    const isMobile = this.props.currentBreakpoint < Breakpoint.Medium;
    return (
      <>
        {this.state.modalOpen && this.renderModal()}
        {isMobile && this.renderMobileModal()}
        {this.renderCarousel()}
      </>
    );
  }
}

export default connect(
  (state): ConnectedPropType => ({
    cloakedImages: state.appShellData.cloakProductImages,
    currentBreakpoint: state.currentBreakpoint,
    theme: getTheme(state.currentTheme),
  }),
)(ProductMedia);

const INITIAL_INDEX = 0;
const Base = styled.div({
  position: 'relative',
  [minMediumMediaQuery]: {
    margin: { xy: 0 },
  },
});
