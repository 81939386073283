import React from 'react';
import connect from 'Shared/connect';
import { updateColor, updateFont, updateText, updateWoffValue } from './action-creators';
import { ProductDesignerType } from 'Shared/State';
import { DesktopDropdown } from './dropdown';
import ColorSelector from './colorSelector';
import { styled } from '@glitz/react';
import { sigma, pixelsToUnit, thin, white, lightSilver, large } from 'Shared/Style';
import DesignTagColorProps from 'Start/DesignTagColorProps.type';
import DesignTagFontProps from 'Start/DesignTagFontProps.type';
import { translate } from '@avensia/scope';
import './style.scss';

type ConnectStateType = ProductDesignerType;

type AvailableColorsType = {
  availableColors: DesignTagColorProps[];
  availableFonts: DesignTagFontProps[];
};

type ConnectActionType = {
  updateColor: (name: string, value: string) => void;
  updateFont: (value: string) => void;
  updateText: (value: string) => void;
  updateWoffValue: (value: string) => void;
};

type PropType = ConnectStateType & ConnectActionType & AvailableColorsType;

const TextBoxWrapper = styled.div({
  margin: {
    y: large,
    x: 0,
  },
  width: '100%',
});

const TextBox = styled.input({
  maxWidth: '100%',
  backgroundColor: white,
  display: 'block',
  border: {
    xy: {
      width: thin,
      style: 'solid',
      color: lightSilver,
    },
  },
  fontSize: sigma,
  letterSpacing: pixelsToUnit(1.3),
  padding: {
    y: 0,
    x: '1.25rem',
  },
  lineHeight: '50px',
  width: '100%',
});

class ProductDesignerConfigurator extends React.Component<PropType> {
  inputEl: HTMLInputElement;
  render() {
    const textBoxEmpty: boolean = this.props.text === '';
    return (
      <styled.Div style={{}}>
        <TextBoxWrapper>
          <TextBox
            type="text"
            maxLength={15}
            value={this.props.text}
            // tslint:disable-next-line: jsx-no-lambda
            onChange={(e: { target: { value: string } }) => this.props.updateText(e.target.value)}
            // tslint:disable-next-line: jsx-no-lambda
            ref={(el: HTMLInputElement) => (this.inputEl = el)}
            // tslint:disable-next-line: jsx-no-lambda
            onFocus={() => this.inputEl.select()}
            css={
              textBoxEmpty && {
                border: {
                  xy: {
                    width: thin,
                    style: 'solid',
                    color: '#ff0000',
                  },
                },
              }
            }
          />
        </TextBoxWrapper>
        <DesktopDropdown
          zIndex={2}
          value={this.props.font}
          woffValue={this.props.woffValue}
          onChangeOption={(value, woffValue) => {
            this.props.updateWoffValue(woffValue), this.props.updateFont(value);
          }}
          label={translate('/TagDesigner/Font')}
          options={
            this.props.availableFonts
              ? this.props.availableFonts
              : [
                  {
                    name: 'Arial',
                    value: 'Arial',
                    woffValue: '',
                    inStock: true,
                  },
                  {
                    name: 'Times',
                    value: 'Times new roman',
                    woffValue: '',
                    inStock: true,
                  },
                ]
          }
        />
        <ColorSelector
          value={this.props.color}
          onChangeOption={(name, value) => this.props.updateColor(name, value)}
          label={translate('/TagDesigner/TextColor')}
          options={
            this.props.availableColors
              ? this.props.availableColors
              : [
                  {
                    name: translate('/TagDesigner/White'),
                    value: '#FFFFFF',
                    inStock: true,
                  },
                  {
                    name: translate('/TagDesigner/NeonYellow'),
                    value: '#D7FE07',
                    inStock: true,
                  },
                  {
                    name: translate('/TagDesigner/NeonOrange'),
                    value: '#FF8800',
                    inStock: true,
                  },
                  {
                    name: translate('/TagDesigner/SignalRed'),
                    value: '#E5002F',
                    inStock: true,
                  },
                  {
                    name: translate('/TagDesigner/NeonBlue'),
                    value: '#008CFF',
                    inStock: true,
                  },
                  {
                    name: translate('/TagDesigner/NeonPink'),
                    value: '#FF31A9',
                    inStock: true,
                  },
                  {
                    name: translate('/TagDesigner/GlowInTheDark'),
                    value: '#87E3A6',
                    inStock: true,
                  },
                  {
                    name: translate('/TagDesigner/Reflex'),
                    value: '#AAAAAA',
                    inStock: true,
                  },
                ]
          }
        />
      </styled.Div>
    );
  }
}

export default connect(
  (state): ConnectStateType => ({
    colorName: state.productDesigner.colorName,
    color: state.productDesigner.color,
    font: state.productDesigner.font,
    woffValue: state.productDesigner.woffValue,
    text: state.productDesigner.text,
  }),
  (dispatch): ConnectActionType => ({
    updateColor(colorName, value) {
      return dispatch(updateColor(colorName, value));
    },
    updateFont(value) {
      return dispatch(updateFont(value));
    },
    updateWoffValue(value) {
      return dispatch(updateWoffValue(value));
    },
    updateText(value) {
      return dispatch(updateText(value));
    },
  }),
)(ProductDesignerConfigurator);
