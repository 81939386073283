import React from 'react';
import { styled } from '@glitz/react';
import { small, pixelsToUnit, minMediumMediaQuery, pageMaxWidth } from 'Shared/Style';
import { Section } from 'Shared/PageLayout';
// import Reviews from 'Product/Reviews';
import { Description, QuickFacts } from './ProductDetails';
import ReviewViewModelType from 'Reviews/Models/ReviewViewModel.type';
import QuickFactViewModelType from 'Product/QuickFactViewModel.type';

type ReviewsPropType = {
  catalogId: number;
  language: string;
  productCode: string;
  averageRating: number;
  topReviews: ReviewViewModelType[];
  totalReviews: number;
  productPageUrl: string;
  isExpandReviews: boolean;
  toggleReviewPanel: () => void;
};

type RequiredPropType = {
  description?: Scope.XhtmlString;
  reviews: ReviewsPropType;
  quickFacts: QuickFactViewModelType[] | undefined;
  isCompact: boolean;
};

type PropType = RequiredPropType & {
  forwardedRef: React.Ref<HTMLDivElement>;
};

function ProductDescriptionSection(props: PropType) {
  const { description, quickFacts, isCompact, forwardedRef } = props;
  return (
    <Base>
      <ProductDetails>
        {description && description.html && !!description.html.length && (
          <Description description={description} forwardedRef={forwardedRef} isCompact={isCompact} />
        )}
        {quickFacts.length > 0 && <QuickFacts quickFacts={quickFacts} />}
      </ProductDetails>
    </Base>
  );
}

export default React.forwardRef(function WithForwardedRefMidSection(
  props: RequiredPropType,
  ref: React.Ref<HTMLDivElement>,
) {
  return <ProductDescriptionSection {...props} forwardedRef={ref} />;
});

const Base = styled(Section, {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 0,
  padding: {
    x: small,
    y: small,
  },
  [minMediumMediaQuery]: {
    flexDirection: 'row',
    maxWidth: pixelsToUnit(pageMaxWidth),
    padding: {
      x: 0,
    },
  },
});

const ProductDetails = styled.div({
  [minMediumMediaQuery]: {
    display: 'flex',
    paddingRight: '1.5rem',
    paddingLeft: '1rem',
    justifyContent: 'space-between',
    width: '100%',
  },
});
