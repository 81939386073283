import React from 'react';
import { styled } from '@glitz/react';
import { translate } from '@avensia/scope';
import Products, { Layout } from 'Product/ProductCard/List';
import EsalesProductForList from '../../../Esales/Models/EsalesProductForList.type';
import { delta, omega, pixelsToUnit, minMediumMediaQuery, epsilon, minTinyMediaQuery } from 'Shared/Style';

type PropType = {
  relatedProducts: EsalesProductForList[];
};

const Base = styled.section({
  padding: {
    x: pixelsToUnit(15),
  },
});

const ResponsiveH2 = styled.h2({
  fontSize: epsilon,
  textAlign: 'center',
  letterSpacing: pixelsToUnit(1.61),
  margin: {
    bottom: pixelsToUnit(28),
  },
  [minTinyMediaQuery]: {
    fontSize: delta,
  },
  [minMediumMediaQuery]: {
    letterSpacing: pixelsToUnit(3.2),
    fontSize: omega,
  },
});

export default (props: PropType) => (
  <Base>
    <ResponsiveH2>{translate('/Product/RelatedProducts')}</ResponsiveH2>
    <Products products={props.relatedProducts} layout={Layout.Block} />
  </Base>
);
