import React from 'react';
import { styled } from '@glitz/react';
import ReviewViewModelType from 'Reviews/Models/ReviewViewModel.type';
import Panel from 'Shared/Panel';
import Icon from 'Shared/Icon/Close';
import { pixelsToUnit, minMediumMediaQuery, minLargeMediaQuery } from 'Shared/Style';
import List from './../List';
import AverageRating, { Spacing, Size } from '../../ProductDetails/AverageRating';
import { translate } from '@avensia/scope';
import AddReviewButton from '../AddReviewButton';
import { openModal, closeModal } from '../action-creators';
import connect from 'Shared/connect';

type ConnectActionType = {
  openModal: () => any;
  closeModal: () => any;
};

type ConnectStateType = {
  isOpen: boolean;
  currentBreakpoint?: number;
};

type PropType = ConnectActionType & {
  list: ReviewViewModelType[];
  isExpandReviews: boolean;
  closeReviews: () => void;
  showMore: (fetchingDone: () => void) => void;
  averageRating: number;
  isCompact: boolean;
  totalReview: number;
  isModalList?: boolean;
  isReviewModal?: boolean;
};

type OwnState = {
  loading: boolean;
};

const MODAL_HEIGHT = '748px';
const MODAL_WIDTH = '657px';
class ViewAllReviews extends React.Component<PropType, OwnState> {
  ref = React.createRef<HTMLDivElement>();
  constructor(props: PropType) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.getMoreReviews();
  }

  getMoreReviews = () => {
    if (!this.state.loading) {
      this.setState({ loading: true }, () => this.props.showMore(() => this.setState({ loading: false })));
    }
  };

  closeReviewsOpenSubmitOwnReview = () => {
    this.props.closeReviews();
    setTimeout(() => this.props.openModal(), 200);
  };

  betweenRange = (x: number, min: number, max: number) => {
    return x >= min && x <= max;
  };

  handleScroll = (e: React.UIEvent) => {
    const bottom = this.betweenRange(
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop,
      e.currentTarget.clientHeight - 2,
      e.currentTarget.clientHeight + 2,
    );
    if (bottom && this.props.list.length < this.props.totalReview) {
      this.getMoreReviews();
    }
  };

  render() {
    return (
      <Panel
        isOpen={this.props.isExpandReviews}
        maxHeight={MODAL_HEIGHT}
        maxWidth={MODAL_WIDTH}
        onClose={this.props.closeReviews}
      >
        <Close css={this.props.isCompact && { top: pixelsToUnit(35) }} onClick={this.props.closeReviews}>
          <StyledIcon />
        </Close>
        <Heading>
          <HeadingText css={this.props.isCompact && { margin: { y: pixelsToUnit(5) } }}>
            {this.props.isCompact
              ? `${translate('/Product/Reviews/Heading')} (${this.props.totalReview})`
              : `${this.props.totalReview} ${translate('/Product/Reviews/Heading')}`}
          </HeadingText>
          <AverageRating
            average={this.props.averageRating}
            spacing={this.props.isCompact ? Spacing.Medium : Spacing.Wide}
            size={this.props.isCompact ? Size.Large : Size.Huge}
          />
        </Heading>
        <ReviewsList onScroll={this.handleScroll}>
          <List key={this.props.list.length} list={this.props.list} isReviewModal={true} />
        </ReviewsList>
        <ButtonWrapper>
          <AddReviewButton onClick={this.closeReviewsOpenSubmitOwnReview} />
        </ButtonWrapper>
      </Panel>
    );
  }
}

export default connect(
  (state): ConnectStateType => ({
    isOpen: state.productReview.isOpen,
    currentBreakpoint: state.currentBreakpoint,
  }),
  (dispatch): ConnectActionType => ({
    openModal: () => dispatch(openModal()),
    closeModal: () => dispatch(closeModal()),
  }),
)(ViewAllReviews);

const Close = styled.div({
  position: 'absolute',
  top: pixelsToUnit(40),
  bottom: pixelsToUnit(20),
  right: pixelsToUnit(20),
  cursor: 'pointer',
  height: 'fit-content',
  [minMediumMediaQuery]: {
    right: pixelsToUnit(40),
  },
});

const StyledIcon = styled(Icon, {
  width: pixelsToUnit(25),
  height: pixelsToUnit(25),
  [minLargeMediaQuery]: {
    width: pixelsToUnit(40),
    height: pixelsToUnit(40),
  },
});

const ButtonWrapper = styled.div({
  marginBottom: '1rem',
});

const ReviewsList = styled.div({
  height: `calc(100% - ${pixelsToUnit(120)})`,
  width: `calc(100% - ${pixelsToUnit(10)})`,
  padding: {
    x: pixelsToUnit(15),
  },
  margin: {
    x: 'auto',
    y: pixelsToUnit(20),
  },
  overflow: 'auto',
  [minMediumMediaQuery]: {
    marginTop: 0,
    padding: {
      x: pixelsToUnit(40),
    },
  },
});

const Heading = styled.div({
  width: '100%',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  paddingBottom: pixelsToUnit(16),
  height: pixelsToUnit(105),
  [minLargeMediaQuery]: {
    height: pixelsToUnit(120),
  },
});

const HeadingText = styled.h1({
  textTransform: 'uppercase',
  letterSpacing: pixelsToUnit(2),
  fontSize: pixelsToUnit(18),
  fontWeight: 'bold',
  lineHeight: '100%',
  marginBottom: pixelsToUnit(10),
  [minLargeMediaQuery]: {
    fontSize: pixelsToUnit(26),
    right: pixelsToUnit(40),
  },
});
