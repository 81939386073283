import React from 'react';
import connect from 'Shared/connect';
import { styled } from '@glitz/react';
import { pixelsToUnit, thin, minMediumMediaQuery, minLargeMediaQuery, lightBlack, black } from 'Shared/Style';
import { translate } from '@avensia/scope';
import Image, { Preset as ImagePreset, Ratio as ImageRatio } from 'Shared/Image/Ratio';
import Link from 'Shared/Link';
import Price, { Type as PriceType } from 'Pricing/Price';
import GwpListViewModelType from './GwpListViewModel.type';

type ConnectedPropType = {
  cloakedImages: boolean;
};

type GiftWithPurchaseType = {
  gwpFreePromotion: GwpListViewModelType;
};

type PropType = GiftWithPurchaseType & ConnectedPropType;

function GwpFreeItem(props: PropType) {
  const { gwpFreePromotion } = props;
  const { giftItem } = gwpFreePromotion;

  return (
    <PromotionContainer>
      <FreeItemTitle>{translate('/Product/GiftWithPurchase/Title')}</FreeItemTitle>
      <ImageAndInfoContainer>
        <PhotoContainer>
          <Link to={giftItem.url}>
            <ImageStyled
              preset={!giftItem.image.includes('.gif') ? ImagePreset.Tiny : undefined}
              ratio={ImageRatio.OneToOne}
              src={giftItem.image}
            />
          </Link>
        </PhotoContainer>
        <ItemDetailsContainer>
          <PromotionDescriptionText>{gwpFreePromotion.promotionConditionDescription}</PromotionDescriptionText>
          <Link to={giftItem.url}>
            <ProductNameText>{giftItem.displayName}</ProductNameText>
          </Link>
          <p>
            {translate('/Product/GiftWithPurchase/PriceLabel')}&nbsp;
            <PriceValue
              current={giftItem.price.original}
              currency={giftItem.price.currency}
              priceType={PriceType.Regular}
            />
          </p>
        </ItemDetailsContainer>
      </ImageAndInfoContainer>
    </PromotionContainer>
  );
}

export default connect((state) => ({
  cloakedImages: state.appShellData.cloakProductImages,
}))(GwpFreeItem);

const PromotionContainer = styled.div({
  border: {
    xy: {
      width: thin,
      style: 'solid',
      color: '#cccccc',
    },
  },
  margin: {
    x: pixelsToUnit(10),
    y: pixelsToUnit(20),
  },
  position: 'relative',
  // flexFlow: 'row nowrap',
  [minMediumMediaQuery]: {
    margin: {
      x: pixelsToUnit(0),
      y: 0,
    },
  },
});

const ImageAndInfoContainer = styled.div({
  display: 'flex',
});

const ItemDetailsContainer = styled.div({
  display: 'flex',
  alignSelf: 'center',
  flexFlow: 'column',
  flexBasis: '100%',
  marginLeft: pixelsToUnit(20),
  textAlign: 'left',
  [minMediumMediaQuery]: {
    marginLeft: pixelsToUnit(20),
    justifyContent: 'center',
  },
});

const PhotoContainer = styled.div({
  flexGrow: 1,
});

const FreeItemTitle = styled.p({
  backgroundColor: (theme) => theme.campaignBackgroundColor,
  color: black,
  display: 'flex',
  justifyContent: 'center',
  fontWeight: 'bold',
  lineHeight: pixelsToUnit(33),
  letterSpacing: pixelsToUnit(2.2),
  fontSize: pixelsToUnit(14),
  textAlign: 'center',
  [minMediumMediaQuery]: {
    top: 0,
    position: 'relative',
    textAlign: 'left',
    fontSize: pixelsToUnit(16),
  },
  [minLargeMediaQuery]: {
    fontSize: pixelsToUnit(18),
  },
});

const PromotionDescriptionText = styled.p({
  fontSize: pixelsToUnit(16),
  lineHeight: pixelsToUnit(20),
  color: lightBlack,
  marginTop: pixelsToUnit(0),
});

const ProductNameText = styled.p({
  fontWeight: 'bold',
  color: lightBlack,
});

const PriceValue = styled(Price, {
  fontWeight: 'bold',
});

const ImageStyled = styled(Image, {
  width: pixelsToUnit(100),
  height: 'auto',
  [minMediumMediaQuery]: {
    width: pixelsToUnit(100),
  },
});
