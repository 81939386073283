export const enum YoutubeStateTypes {
  UNSTARTED = -1,
  ENDED = 0,
  PLAYING = 1,
  PAUSED = 2,
  BUFFERING = 3,
  CUED = 5,
}
export interface YouTubeAPI {
  Player: { new (elementId: string, options?: Options): YouTubePlayerType };
}

export type EventType =
  | 'ready'
  | 'stateChange'
  | 'playbackQualityChange'
  | 'playbackRateChange'
  | 'error'
  | 'apiChange'
  | 'volumeChange';

export interface Options {
  width?: number;
  height?: number;
  videoId?: string;
  playerVars?: {
    autoplay?: 0 | 1;
    cc_lang_pref?: string;
    cc_load_policy?: 1;
    color?: 'red' | 'white';
    controls?: 0 | 1;
    disablekb?: 0 | 1;
    enablejsapi?: 0 | 1;
    end?: number;
    fs?: 0 | 1;
    hl?: string;
    iv_load_policy?: 1 | 3;
    list?: string;
    listType?: 'playlist' | 'search' | 'user_uploads';
    loop?: 0 | 1;
    modestbranding?: 1;
    origin?: string;
    playlist?: string;
    playsinline?: 0 | 1;
    rel?: 0 | 1;
    start?: number;
    widget_referrer?: string;
  };
  events?: Events;
}
export interface PlayerEvent {
  target: YouTubePlayerType;
}

export interface PlayerEventHandler<TEvent extends PlayerEvent> {
  (event: TEvent): void;
}
export interface Events {
  onReady?: PlayerEventHandler<PlayerEvent>;
  onStateChange?: PlayerEventHandler<OnStateChangeEvent>;
  onPlaybackQualityChange?: PlayerEventHandler<OnPlaybackQualityChangeEvent>;
  onPlaybackRateChange?: PlayerEventHandler<OnPlaybackRateChangeEvent>;
  onError?: PlayerEventHandler<OnErrorEvent>;
  onApiChange?: PlayerEventHandler<PlayerEvent>;
}
export interface OnStateChangeEvent extends PlayerEvent {
  data: YoutubeStateTypes;
}

export interface OnErrorEvent extends PlayerEvent {
  data: PlayerError;
}

export type YouTubePlayerType = {
  addEventListener: (event: string, listener: Function) => void;
  destroy: () => void;
  getAvailablePlaybackRates: () => ReadonlyArray<number>;
  getAvailableQualityLevels: () => ReadonlyArray<string>;
  getCurrentTime: () => number;
  getDuration: () => number;
  getIframe: () => Object;
  getOption: () => any;
  getOptions: () => any;
  setOption: () => void;
  setOptions: () => void;
  cuePlaylist: (
    playlist: string | ReadonlyArray<string>,
    index?: number,
    startSeconds?: number,
    suggestedQuality?: string,
  ) => void;
  loadPlaylist: (
    playlist: string | ReadonlyArray<string>,
    index?: number,
    startSeconds?: number,
    suggestedQuality?: string,
  ) => void;
  getPlaylist: () => ReadonlyArray<string>;
  getPlaylistIndex: () => number;
  getPlaybackQuality: () => string;
  getPlaybackRate: () => number;
  getPlayerState: () => number;
  getVideoEmbedCode: () => string;
  getVideoLoadedFraction: () => number;
  getVideoUrl: () => string;
  getVolume: () => number;
  cueVideoById: (videoId: string, startSeconds?: number, suggestedQuality?: string) => void;
  cueVideoByUrl: (mediaContentUrl: string, startSeconds?: number, suggestedQuality?: string) => void;
  loadVideoByUrl: (mediaContentUrl: string, startSeconds?: number, suggestedQuality?: string) => void;
  loadVideoById: (videoId: string, startSeconds?: number, suggestedQuality?: string) => void;
  isMuted: () => boolean;
  mute: () => void;
  nextVideo: () => void;
  pauseVideo: () => void;
  playVideo: () => void;
  playVideoAt: (index: number) => void;
  previousVideo: () => void;
  removeEventListener: (event: string, listener: Function) => void;
  seekTo: (seconds: number, allowSeekAhead: boolean) => void;
  setLoop: (loopPlaylists: boolean) => void;
  setPlaybackQuality: (suggestedQuality: string) => void;
  setPlaybackRate: (suggestedRate: number) => void;
  setShuffle: (shufflePlaylist: boolean) => void;
  setSize: (width: number, height: number) => Object;
  setVolume: (volume: number) => void;
  stopVideo: () => void;
  unMute: () => void;
};

export default YouTubePlayerType;

export const enum PlayerError {
  InvalidParam = 2,
  Html5Error = 5,
  VideoNotFound = 100,
  EmbeddingNotAllowed = 101,
  EmbeddingNotAllowed2 = 150,
}
export interface OnPlaybackQualityChangeEvent extends PlayerEvent {
  data: string;
}
export interface OnPlaybackRateChangeEvent extends PlayerEvent {
  data: number;
}
