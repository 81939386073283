import React from 'react';
import { styled } from '@glitz/react';
import { translate, isIOS, pathCombine, postJson, isIE, isEdge, Breakpoint } from '@avensia/scope';
import H2 from 'Shared/Generic/H2';
import {
  minTinyMediaQuery,
  minSmallMediaQuery,
  pixelsToUnit,
  alto,
  amaranth,
  darkerGray,
  thin,
  kappa,
  sigma,
  chateaugreen,
  small,
  transition,
} from 'Shared/Style';
import FeedbackButton from 'Shared/Button/Feedback';
import Input from 'Shared/Fields/Text';
import { Style } from '@glitz/type';
import { termsAndConditionPage } from 'Shared/known-urls';
import Link from 'Shared/Link';
import connect from 'Shared/connect';
import CurrentUserTypeBase from 'Account/CurrentUserAccountViewModel.type';
import { emailPattern } from 'Shared/email-pattern';
import freezeScroll from 'Shared/freeze-scroll';

export type FormDataType = {
  productCode: string;
};

type ConnectStateType = {
  loggedInUser: CurrentUserTypeBase;
  currentBreakpoint: number;
};

type RequiredPropType = {
  pageUrl: string;
  productCode: string;
  onClose: () => void;
};

type StateType = {
  email: string;
  isInvalid: boolean;
  hasError: boolean;
  success: boolean;
};

type PropType = RequiredPropType & ConnectStateType;

class Modal extends React.Component<PropType, StateType> {
  unfreezeScroll: () => void;
  constructor(props: PropType) {
    super(props);
    this.state = { email: '', isInvalid: false, hasError: false, success: false };
  }

  componentDidMount() {
    this.scrollFreeze();
    if (isIOS()) {
      document.body.setAttribute('style', 'position: fixed');
    }

    if (this.props.loggedInUser.isLoggedIn) {
      this.setState({ email: this.props.loggedInUser.email });
    }
  }

  componentWillUnmount() {
    this.scrollFreeze();
    if (isIOS()) {
      document.body.removeAttribute('style');
    }
  }

  scrollFreeze() {
    if (this.props.currentBreakpoint < Breakpoint.Medium) {
      if (this.unfreezeScroll) {
        this.unfreezeScroll();
      }
      this.unfreezeScroll = !this.unfreezeScroll ? freezeScroll() : null;
    }
  }

  submitWatchRequest = async () => {
    const code = this.props.productCode;
    const email = this.state.email;

    try {
      const url = pathCombine(this.props.pageUrl, 'AddToWatchList');
      const success: boolean = await postJson(url, { code, email });
      if (success) {
        this.setState(() => ({
          email: '',
          hasError: false,
          success: true,
        }));
        setTimeout(() => {
          this.props.onClose();
        }, 2000);
      }
      else {
        this.setState(() => ({
          email: email,
          hasError: true,
          success: false
        }));
      }
    } catch (error) {
      this.setState(() => ({
        email: '',
        hasError: true,
        success: false,
      }));
      return Promise.reject(null);
    }
  };

  validateInput(input: string) {
    this.setState({ isInvalid: false, hasError: false });
    const pattern = emailPattern;
    const isValid = pattern.test(input);

    return isValid;
  }

  inputEmailAddress = (e: React.FormEvent<HTMLInputElement>) => {
    const email = (e.target as HTMLInputElement).value;
    this.setState({
      email,
      isInvalid: !this.validateInput(email),
    });
  };

  render() {
    const inputInvalidStyle: Style = {
      border: {
        xy: {
          color: amaranth,
        },
      },
    };

    const buttonSuccessStyle: Style = {
      backgroundColor: chateaugreen,
      backgroundImage: 'transparent',
      pointerEvents: 'none',
    };

    return (
      <Body>
        <Header>{translate('/Product/WatchableProduct/WatchableButtonText')}</Header>
        <Container>
          <SignupInfo>{translate('/Product/WatchableProduct/WatchableSignupInfo')}</SignupInfo>
          <EmailLabel>{translate('/Product/WatchableProduct/Email')} *</EmailLabel>
          <Form>
            <EmailInput
              css={{
                ...(this.state.isInvalid && inputInvalidStyle),
                ...((isIE() || isEdge()) && {
                  ':-ms-input-placeholder': { color: alto },
                }),
              }}
              autoComplete="email"
              invalid={this.state.isInvalid}
              name="email"
              placeholder={translate('/Product/WatchableProduct/EmailPlaceholder')}
              required
              type="email"
              value={this.state.email}
              onChange={this.inputEmailAddress}
            />
            <InvalidEmail css={this.state.isInvalid && { opacity: 1 }}>
              {translate('/Product/WatchableProduct/IncorrectEmail')}
            </InvalidEmail>
            <Submit
              disabled={this.state.isInvalid || this.state.email === ''}
              css={this.state.success && buttonSuccessStyle}
              type="submit"
              onClick={this.submitWatchRequest}
            >
              {translate('/Shared/Send')}
            </Submit>
            {this.state.hasError && <ErrorMessage>{translate('/Product/WatchableProduct/ServerError')}</ErrorMessage>}
          </Form>
          {termsAndConditionPage() && (
            <PrivacyPolicy to={termsAndConditionPage()} target="_blank">
              {translate('/Product/WatchableProduct/PrivacyPolicy')}
            </PrivacyPolicy>
          )}
        </Container>
      </Body>
    );
  }
}

export default connect(
  (state): ConnectStateType => ({
    loggedInUser: state.currentUser,
    currentBreakpoint: state.currentBreakpoint,
  }),
)(Modal);

const GUTTER_INIT = 10;
const GUTTER_TINY = 49;
const GUTTER_SMALL = 113;

const EmailInput = styled(Input, {
  border: {
    xy: {
      style: 'solid',
      color: alto,
      width: thin,
    },
  },
  padding: {
    y: 0,
    x: small,
  },
  textAlign: 'left',
});

const Form = styled.form({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
});

const Submit = styled(FeedbackButton, {
  letterSpacing: pixelsToUnit(0.8),
  margin: {
    bottom: small,
  },
});

const Body = styled.div({
  width: `calc(100% - ${GUTTER_INIT * 2}px)`,
  margin: {
    y: 0,
    x: 'auto',
  },
  textAlign: 'center',
  [minTinyMediaQuery]: {
    width: `calc(100% - ${GUTTER_TINY * 2}px)`,
  },
  [minSmallMediaQuery]: {
    width: `calc(100% - ${GUTTER_SMALL * 2}px)`,
  },
});

const Header = styled(H2, {
  textAlign: 'center',
  textTransform: 'uppercase',
  marginBottom: pixelsToUnit(25),
});

const SignupInfo = styled.p({
  marginBottom: pixelsToUnit(25),
});

const EmailLabel = styled.p({
  textAlign: 'left',
  paddingLeft: small,
});

const Container = styled.div({
  padding: {
    x: small,
  },
  ':after': {
    display: 'block',
    content: '""',
    height: pixelsToUnit(30),
  },
});

const ErrorMessage = styled.div({
  color: amaranth,
  fontSize: sigma,
});

const InvalidEmail = styled(ErrorMessage, {
  alignSelf: 'flex-start',
  opacity: 0,
  margin: {
    y: pixelsToUnit(5),
  },
  paddingLeft: small,
  ...transition({ property: 'opacity' }),
});

const PrivacyPolicy = styled(Link, {
  fontSize: kappa,
  lineHeight: pixelsToUnit(16),
  color: darkerGray,
});
