import React from 'react';
import { styled } from '@glitz/react';
import { pixelsToUnit, transition, sigma, minMediumMediaQuery, minTinyMediaQuery } from 'Shared/Style';
import Ul from 'Shared/Generic/Ul';
import Color from 'color';
import { translate } from '@avensia/scope';

type OptionType = {
  name: string;
  value: string;
  inStock?: boolean;
};

type DesktopPropType = {
  value: string;
  label?: string;
  options: OptionType[];
  zIndex?: number;
  onChangeOption?: (name: string, value: string) => any;
  onWatchableOpen?: () => void;
};

type StateType = {
  colorName: string;
};

export default class ColorSelector extends React.Component<DesktopPropType, StateType> {
  constructor(props: DesktopPropType) {
    super(props);
    this.state = {
      colorName: translate('/TagDesigner/no_color_selected'),
    };
  }
  unsubscribeBodyClose: () => any;

  setColorName = (name: string) => {
    this.setState({
      colorName: name,
    });
  };

  selectOption = (name: string, value: string) => {
    if (this.props.onChangeOption) {
      this.props.onChangeOption(name, value);
    }
  };

  handlingSelect(value: string, name: string) {
    this.setColorName(name);
    this.selectOption(name, value);
  }

  render() {
    const colorName = this.state.colorName;
    const variants = this.props.options.map(variant => ({
      text: variant.name,
      value: variant.value,
      inStock: variant.inStock,
    }));

    return (
      <Base>
        {this.props.label && (
          <ColorNameContainer>
            {this.props.label}:<ColorName>{colorName}</ColorName>
          </ColorNameContainer>
        )}
        <Options>
          {variants.map(option => (
            <OptionLi key={option.value} onClick={() => this.handlingSelect(option.value, option.text)}>
              <Option color={option.value} name={option.text} selected={option.value === this.props.value} />
            </OptionLi>
          ))}
        </Options>
      </Base>
    );
  }
}

const Base = styled.div({
  margin: {
    y: '1rem',
    x: 0,
  },
});

const ColorNameContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  fontWeight: 'bold',
  fontSize: sigma,
  textTransform: 'uppercase',
  color: '#ababab',
  margin: {
    y: '0.5rem',
    x: '1.25rem',
  },
});

const ColorName = styled.div({
  color: 'black',
  marginLeft: '0.2rem',
});

const Options = styled(Ul, {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  listStyleType: 'none',
  [minMediumMediaQuery]: {
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
});

const OptionLi = styled.li({
  margin: {
    x: '0.2rem',
    y: '0.2rem',
  },
  [minTinyMediaQuery]: {
    flexWrap: 'nowrap',
    margin: {
      x: '0.4rem',
    },
  },
});

type OptionPropType = {
  color: string;
  name: string;
  selected: boolean;
};

const Option = ({ color, name, selected }: OptionPropType) => {
  const bkgColor = Color(color);
  const highlightColor = bkgColor.lighten(0.5);
  const borderColor = bkgColor.desaturate(0.1).darken(0.1);
  return (
    <styled.Div
      title={name}
      css={{
        [minMediumMediaQuery]: { width: pixelsToUnit(42), height: pixelsToUnit(42) },
        width: pixelsToUnit(38),
        height: pixelsToUnit(38),
        borderRadius: '50%',
        backgroundColor: bkgColor.string(),
        boxShadow: selected ? '3px 3px 7px rgba(0,0,0,0.3)' : 'none',
        backgroundImage: `linear-gradient(135deg, ${highlightColor.string()} 0%, ${bkgColor.string()} 100%)`,
        border: selected
          ? {
              xy: {
                width: pixelsToUnit(6),
                style: 'solid',
                color: borderColor.string(),
              },
            }
          : {
              xy: {
                width: pixelsToUnit(1),
                style: 'solid',
                color: borderColor.string(),
              },
            },
        cursor: 'pointer',
        transform: selected ? 'scale(1.05)' : 'none',
        ':hover': {
          boxShadow: selected ? '3px 3px 7px rgba(0,0,0,0.3)' : '3px 3px 7px rgba(0,0,0,0.3)',
        },
        ...transition({ property: ['transform', 'box-shadow'], duration: '.2s' }),
      }}
    />
  );
};
