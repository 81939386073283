import React from 'react';
import { styled } from '@glitz/react';
import Link from 'Shared/Link';
import { thin, minMediumMediaQuery, pixelsToUnit, scorpion, sigma } from 'Shared/Style';
import { COLOR_TRANSPARENT } from './shared';

type PropType = {
  brand: string;
  brandUrl: string;
};

const StyledDiv = styled.div({
  display: 'inline-block',
  borderBottom: {
    width: thin,
    style: 'solid',
    color: COLOR_TRANSPARENT,
  },
  fontSize: sigma,
  ':hover': {
    textDecoration: 'underline',
  },
  [minMediumMediaQuery]: {
    marginBottom: pixelsToUnit(7),
  },
});

const Brand = (props: PropType) => (
  <StyledDiv>
    {props.brandUrl ? (
      <Link
        css={{
          color: scorpion,
        }}
        to={props.brandUrl}
      >
        {props.brand}
      </Link>
    ) : (
      props.brand
    )}
  </StyledDiv>
);

export default Brand;
