import React from 'react';
import { styled } from '@glitz/react';
import { pseudo } from '@glitz/core';
import { translate } from '@avensia/scope';
import SizeGuidePageViewModelType from 'SizeGuide/SizeGuideViewModel.type';
import IconMeasure from 'Shared/Icon/Measure';
import {
  corduroy,
  white,
  delta,
  epsilon,
  theta,
  sigma,
  minSmallMediaQuery,
  minTinyMediaQuery,
  minMediumMediaQuery,
  pixelsToUnit,
} from 'Shared/Style';
import Flyout, { Position, Right } from 'Shared/Flyout';
import Icon from 'Shared/Icon/Close';
import { COLOR_NOT_TRANSPARENT } from './shared';

type SizeGuidePropType = {
  sizeGuide: SizeGuidePageViewModelType;
};
type PropType = SizeGuidePropType;
type StateType = {
  isOpen: boolean;
};

type TablePropType = {
  sizeHeader: string[];
  sizes: string[][];
};

type ImagePropType = {
  imageUrl: string;
};

type ImageStateType = {
  loaded: boolean;
};

const TABLE_MICRO_GUTTER_WIDTH = 25;
const TABLE_TINY_GUTTER_WIDTH = 75;
const TABLE_SMALL_GUTTER_WIDTH = 95;
const TABLE_COLUMN_WIDTH = 120;

const Container = styled.div({
  margin: {
    y: pixelsToUnit(15),
  },
  [minMediumMediaQuery]: {
    margin: 0,
  },
});

const Text = styled.div({
  borderBottom: {
    width: pixelsToUnit(1),
    style: 'solid',
    color: COLOR_NOT_TRANSPARENT,
  },
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: epsilon,
  lineHeight: pixelsToUnit(20),
});

const Close = styled.div({
  padding: { xy: pixelsToUnit(10) },
  display: 'flex',
  justifyContent: 'flex-end',
  [minMediumMediaQuery]: {
    right: pixelsToUnit(10),
    top: pixelsToUnit(20),
  },
});

const StyledIcon = styled(Icon, {
  width: pixelsToUnit(40),
  height: pixelsToUnit(40),
  cursor: 'pointer',
});

const Modal = styled.div({});

const Body = styled.div({
  padding: {
    top: pixelsToUnit(15),
    x: 0,
  },
  height: '100%',
  [minMediumMediaQuery]: {
    padding: {
      y: pixelsToUnit(100),
      x: 0,
    },
  },
});

const Header = styled.div({
  textTransform: 'uppercase',
  textAlign: 'center',
  fontSize: delta,
  letterSpacing: pixelsToUnit(2.7),
  color: '#202020',
  lineHeight: 'normal',
  margin: {
    y: pixelsToUnit(6),
    x: 0,
  },
});

const Description = styled(Header, {
  fontSize: sigma,
  letterSpacing: pixelsToUnit(2.1),
  color: '#8b8b8b',
});

const TableContainer = styled.div({
  maxWidth: 'calc(100% - 20px)',
  maxHeight: 'calc(100% - 50px)',
  margin: {
    top: pixelsToUnit(36),
    x: 'auto',
  },
  overflow: 'auto',
});

const ImageContainer = styled.div({
  maxWidth: `calc(100% - ${TABLE_MICRO_GUTTER_WIDTH * 2}px)`,
  maxHeight: 'calc(100% - 50px)',
  margin: {
    top: pixelsToUnit(36),
    bottom: 0,
    x: 'auto',
  },
  overflow: 'auto',
  [minTinyMediaQuery]: {
    width: `calc(100% - ${TABLE_TINY_GUTTER_WIDTH * 2}px)`,
  },
});

const Image = styled.img({
  maxWidth: '100%',
  height: 'auto',
  width: '100%',
});

const Table = styled.table({
  backgroundColor: (theme) => theme.sizeGuideHeaderBackgroundColor,
  backgroundImage: (theme) => theme.sizeGuideHeaderBackgroundImage,
  borderCollapse: 'collapse',
  tableLayout: 'auto',
  width: '100%',
});

const TableRow = styled.tr({
  lineHeight: pixelsToUnit(20),
  ...pseudo(':nth-child(odd)', {
    backgroundColor: '#f6f6f6',
  }),
  ...pseudo(':nth-child(even)', {
    backgroundColor: white,
  }),
});

const TableRowHeader = styled(TableRow, {
  lineHeight: pixelsToUnit(23),
  ':only-child': {
    backgroundColor: 'transparent',
  },
});

const TableData = styled.td({
  width: `${TABLE_COLUMN_WIDTH}px`,
  color: corduroy,
  fontSize: theta,
  letterSpacing: 'normal',
  padding: {
    y: pixelsToUnit(12),
    x: pixelsToUnit(5.5),
  },
  textAlign: 'left',
  ':first-child': {
    paddingLeft: pixelsToUnit(10),
  },
});

const TableHeader = styled(TableData, {
  paddingTop: pixelsToUnit(10),
  paddingBottom: pixelsToUnit(10),
  color: white,
  fontSize: epsilon,
  letterSpacing: pixelsToUnit(0.5),
});

class SizeGuideImage extends React.Component<ImagePropType, ImageStateType> {
  state: ImageStateType = {
    loaded: false,
  };

  handleImageLoaded = () => {
    this.setState({
      loaded: true,
    });
  };

  render() {
    return (
      <ImageContainer>
        <Image
          css={{ display: this.state.loaded ? 'block' : 'none' }}
          src={this.props.imageUrl}
          onLoad={this.handleImageLoaded}
        />
      </ImageContainer>
    );
  }
}

class SizeGuideTable extends React.Component<TablePropType> {
  table: HTMLTableElement;

  render() {
    return (
      this.props.sizeHeader &&
      this.props.sizes && (
        <TableContainer
          css={{
            height: 'auto',
          }}
        >
          <Table
            // tslint:disable-next-line:jsx-no-lambda
            ref={(table: HTMLTableElement) => (this.table = table)}
          >
            <thead>
              <TableRowHeader>
                {this.props.sizeHeader.map((header, i) => (
                  <TableHeader key={header + i}>{header}</TableHeader>
                ))}
              </TableRowHeader>
            </thead>
            <tbody>
              {this.props.sizes.map((size, i) => (
                <TableRow key={size.length + i}>
                  {size.map((data, i) => (
                    <TableData key={data + i}>{data}</TableData>
                  ))}
                </TableRow>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      )
    );
  }
}

type ContentPropType = SizeGuidePropType & {
  onClose: () => void;
};

const WideRight = styled(Right, {
  maxWidth: 'unset',
  overflow: 'auto',
  width: '100%',
  [minMediumMediaQuery]: {
    width: pixelsToUnit(800),
  },
});

const Content = (props: ContentPropType) => (
  <Modal>
    <Close
      css={{
        [minSmallMediaQuery]: {
          right: props.sizeGuide.imageUrl ? `${TABLE_TINY_GUTTER_WIDTH}px` : `${TABLE_SMALL_GUTTER_WIDTH}px`,
        },
      }}
    >
      <StyledIcon onClick={props.onClose} />
    </Close>
    <Body>
      <Header>{props.sizeGuide.title} </Header>
      <Description>{props.sizeGuide.description}</Description>
      {props.sizeGuide.imageUrl ? (
        <SizeGuideImage imageUrl={props.sizeGuide.imageUrl} />
      ) : (
        <SizeGuideTable sizeHeader={props.sizeGuide.sizeHeader} sizes={props.sizeGuide.sizes} />
      )}
    </Body>
  </Modal>
);

export default class SizeGuide extends React.Component<PropType, StateType> {
  state = {
    isOpen: false,
  };

  toggleModal = () =>
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));

  render() {
    return (
      <Container>
        <IconMeasure css={{ marginRight: pixelsToUnit(10) }} />
        <Text onClick={this.toggleModal}>{translate('/Product/SizeGuide')}</Text>
        <Flyout
          positionComponent={WideRight}
          position={Position.Right}
          toggle={this.toggleModal}
          open={this.state.isOpen}
        >
          <Content sizeGuide={this.props.sizeGuide} onClose={this.toggleModal} />
        </Flyout>
      </Container>
    );
  }
}
