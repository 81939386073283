import React from 'react';
import { styled } from '@glitz/react';
import { pseudo } from '@glitz/core';
import { translate, isIE } from '@avensia/scope';
import ReviewViewModelType from 'Reviews/Models/ReviewViewModel.type';
import {
  pixelsToUnit,
  black,
  sigma,
  kappa,
  minMicroMediaQuery,
  minTinyMediaQuery,
  minMediumMediaQuery,
  surfCrest,
  silverchalice,
  small,
} from 'Shared/Style';
import connect from 'Shared/connect';
import { Star, StarOutline } from 'Shared/Icon/Star';
import Ratings from './Ratings/ReadOnly';
import { Style } from '@glitz/type';
import { ForExtensive, ForCompact } from 'Shared/Viewport';

type ReviewPropType = {
  review: ReviewViewModelType;
};

type ConnectStateType = {
  adminReviewResponseLogo: string;
  siteName: string;
};

type PropType = ReviewPropType & ConnectStateType & { isModal?: boolean };

function formatDateTime(isoDateTime: string) {
  const date = new Date(isoDateTime);
  const year = date.getFullYear();
  let month: string | number = date.getMonth() + 1;
  let dt: string | number = date.getDate();

  if (dt < 10) {
    dt = '0' + dt;
  }
  if (month < 10) {
    month = '0' + month;
  }
  return year + '-' + month + '-' + dt;
}

const Box = (props: PropType) => {
  const reviewModalStyle: Style = {
    [minMediumMediaQuery]: {
      flexBasis: props.review.reviewAdminAnswer ? '50%' : 'auto',
    },
  };

  const reviewStyle: Style = {
    flexBasis: props.review.reviewAdminAnswer ? '50%' : 'auto',
  };

  const adminResponseStyle: Style = isIE() && {
    maxWidth: '100%',
    [minMediumMediaQuery]: {
      flexBasis: 0,
      flexGrow: 1,
    },
  };

  return (
    <Base css={props.isModal ? { width: '100%' } : { width: '100%', [minMediumMediaQuery]: { width: '49%' } }}>
      <Review css={props.isModal ? reviewModalStyle : reviewStyle}>
        <NameAndRatings>
          <Alias>{props.review.aliasName}</Alias>
          <Ratings css={{ display: 'inline' }} rating={props.review.rating} star={FullStar} starOutline={DefaultStar} />
          <ForCompact>
            <CompactDate>
              <CreatedText>{translate('/Product/Reviews/Created')}:&nbsp;</CreatedText>
              {formatDateTime(props.review.publishDate)}
            </CompactDate>
          </ForCompact>
        </NameAndRatings>
        <Text css={isIE() && { maxWidth: '100%' }}>{props.review.text}</Text>
        <ForExtensive>
          <ExtensiveDate>
            <CreatedText>{translate('/Product/Reviews/Created')}:&nbsp;</CreatedText>
            {formatDateTime(props.review.publishDate)}
          </ExtensiveDate>
        </ForExtensive>
      </Review>
      {props.review.reviewAdminAnswer && (
        <Admin css={adminResponseStyle}>
          {props.adminReviewResponseLogo ? (
            <AdminLogo src={props.adminReviewResponseLogo} alt={props.siteName} />
          ) : (
            <Label>{`${props.siteName} ${translate('/Product/Reviews/List/Responds')}`}</Label>
          )}
          <Answer>{props.review.reviewAdminAnswer}</Answer>
        </Admin>
      )}
    </Base>
  );
};
export default connect(
  (state): ConnectStateType => ({
    adminReviewResponseLogo: state.appShellData.siteSettings.adminReviewResponseLogo?.url,
    siteName: state.appShellData.siteName,
  }),
)(Box);

const Base = styled.div({
  display: 'flex',
  flexDirection: 'column',
  margin: {
    top: pixelsToUnit(10),
    bottom: pixelsToUnit(20),
  },
  [minMediumMediaQuery]: {
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
});

const Review = styled.div({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  flexShrink: 0,
  [minMediumMediaQuery]: {
    flexGrow: 0,
    width: '100%',
  },
});

const NameAndRatings = styled.div({
  alignSelf: 'stretch',
  display: 'flex',
  [minMediumMediaQuery]: {
    alignSelf: 'start',
  },
});

const Name = styled.span({
  marginRight: pixelsToUnit(10),
  fontSize: sigma,
  fontWeight: 'bold',
});

const CreatedText = styled.span({
  display: 'none',
  [minMicroMediaQuery]: {
    display: 'inline-block',
  },
});

const Alias = styled.div({
  marginRight: pixelsToUnit(5),
  marginTop: pixelsToUnit(3),
  fontWeight: 'bold',
  width: 'auto',
  maxWidth: pixelsToUnit(70),
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  [minTinyMediaQuery]: {
    marginRight: pixelsToUnit(10),
    maxWidth: '50%',
  },
});

const ratingsStyled = styled({
  width: pixelsToUnit(16),
  height: pixelsToUnit(15),
  verticalAlign: 'middle',
  ...pseudo(':not(:last-child)', {
    marginRight: pixelsToUnit(2),
  }),
  [minTinyMediaQuery]: {
    ...pseudo(':not(:last-child)', {
      marginRight: pixelsToUnit(4),
    }),
  },
});

const FullStar = ratingsStyled(Star);
const DefaultStar = ratingsStyled(StarOutline);

const Comments = styled.div({
  color: black,
  fontSize: sigma,
  textAlign: 'left',
});

const Text = styled(Comments, {
  alignSelf: 'stretch',
  backgroundColor: (theme) => theme.cardBackgroundColor,
  marginTop: pixelsToUnit(8),
  padding: {
    y: pixelsToUnit(15),
    x: pixelsToUnit(22),
  },
  [minMediumMediaQuery]: {
    marginTop: pixelsToUnit(15),
  },
});

const dateCreatedStyled = styled({
  alignSelf: 'flex-end',
  color: silverchalice,
  fontSize: kappa,
  letterSpacing: pixelsToUnit(0.3),
  lineHeight: pixelsToUnit(22),
});

const ExtensiveDate = dateCreatedStyled(styled.Div, {
  marginTop: pixelsToUnit(14),
});

const CompactDate = dateCreatedStyled(styled.Div, {
  marginLeft: pixelsToUnit(5),
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'flex-end',
  [minTinyMediaQuery]: {
    marginLeft: pixelsToUnit(10),
  },
});

const Label = styled(Name, {
  ':after': {
    content: "': '",
  },
});

const Answer = styled(Comments, {
  backgroundColor: surfCrest,
  marginTop: pixelsToUnit(14),
  padding: {
    y: small,
    x: pixelsToUnit(20),
  },
});

const Admin = styled.div({
  flexGrow: 1,
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  textAlign: 'left',
  [minMediumMediaQuery]: {
    flexGrow: 1,
    flexShrink: 1,
    marginTop: pixelsToUnit(90),
    marginLeft: pixelsToUnit(20),
  },
});

const AdminLogo = styled.img({
  height: pixelsToUnit(50),
  left: pixelsToUnit(-25),
  position: 'absolute',
  top: pixelsToUnit(-13.5),
});
