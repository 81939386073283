import React from 'react';
import { Style } from '@glitz/type';
import { styled } from '@glitz/react';
import { translate } from '@avensia/scope';
import {
  mountainmeadow,
  wildwatermelon,
  large,
  delta,
  sigma,
  gamma,
  epsilon,
  minMediumMediaQuery,
  pixelsToUnit,
} from 'Shared/Style';
import { Basic } from 'Shared/PageLayout';
import IconCheck from 'Shared/Icon/Check';
import IconRemove from 'Shared/Icon/RemovePlain';
import QuickFactViewModelType from 'Product/QuickFactViewModel.type';

type PropType = {
  quickFacts: QuickFactViewModelType[];
};

type DisplayFieldsPropType = Array<{
  label: string;
  value: string | boolean;
}>;

const Caption = styled.h2({
  fontSize: epsilon,
  fontWeight: 'bold',
  lineHeight: 'normal',
  [minMediumMediaQuery]: {
    fontSize: delta,
    letterSpacing: pixelsToUnit(0.4),
  },
});

const Row = styled.div({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
  margin: {
    y: '12px',
    x: 0,
  },
  ':first-of-type': {
    marginTop: pixelsToUnit(9),
  },
  [minMediumMediaQuery]: {
    ':first-of-type': {
      marginTop: pixelsToUnit(16),
    },
  },
});

const RowData = styled.div({
  flex: {
    grow: 0,
    shrink: 0,
    basis: '50%',
  },
  overflowWrap: 'break-word',
  minWidth: 0, // to enable break word in firefox
  textAlign: 'right',
  fontSize: sigma,
  [minMediumMediaQuery]: {
    fontSize: epsilon,
  },
});

const RowHeader = styled(RowData, {
  textAlign: 'left',
});

const QuickFactsCaption = styled.h2({
  fontSize: delta,
  marginBottom: large,
  letterSpacing: pixelsToUnit(2),
  lineHeight: 'normal',
  textTransform: 'uppercase',
  [minMediumMediaQuery]: {
    fontSize: gamma,
    marginBottom: pixelsToUnit(20),
    letterSpacing: pixelsToUnit(2.3),
  },
});

const QuickFactsRow = styled.div({
  display: 'flex',
  textAlign: 'left',
  width: '100%',
  margin: { y: 0, x: 'auto' },
  flexDirection: 'column',
});

const Table = styled.div({
  flex: {
    grow: 1,
    shrink: 1,
    basis: '47.5%',
  },
  ':first-child': {
    marginBottom: pixelsToUnit(30),
  },
  [minMediumMediaQuery]: {
    ':first-child': {
      marginBottom: 0,
    },
  },
});

const Yes = styled(IconCheck, {
  color: mountainmeadow,
});

const No = styled(IconRemove, {
  color: wildwatermelon,
  display: 'inline-block',
  verticalAlign: 'middle',
});

const ColumnGutter = styled.div({
  flex: {
    grow: 0,
    shrink: 0,
    basis: '5%',
  },
});

const getLabel = (key: string) => {
  switch (key) {
    case 'circumference':
      return translate('/Product/QuickFacts/Size/Circumference');
    case 'length':
      return translate('/Product/QuickFacts/Size/Length');
    case 'penetrationlength':
      return translate('/Product/QuickFacts/Size/PenetrationLength');
    case 'diameter':
      return translate('/Product/QuickFacts/Size/Diameter');
    case 'diametermax':
      return translate('/Product/QuickFacts/Size/DiameterMax');
    case 'diametermin':
      return translate('/Product/QuickFacts/Size/DiameterMin');
    case 'innerdiameter':
      return translate('/Product/QuickFacts/Size/InnerDiameter');
    case 'elasticity':
      return translate('/Product/QuickFacts/Details/Elasticity');
    case 'material':
      return translate('/Product/QuickFacts/Details/Material');
    case 'watersafe':
      return translate('/Product/QuickFacts/Details/WaterSafe');
    case 'batteriesincluded':
      return translate('/Product/QuickFacts/Details/BatteriesIncluded');
    case 'batteriesinformationtype':
      return translate('/Product/QuickFacts/Details/BatteriesInformationType');
    case 'vibration':
      return translate('/Product/QuickFacts/Details/Vibration');
    case 'rpm':
      return translate('/Product/QuickFacts/Details/RPM');
    case 'maxnoise':
      return translate('/Product/QuickFacts/Details/MaxNoise');
    case 'ftalates':
      return translate('/Product/QuickFacts/Details/Ftalates');
    case 'parabens':
      return translate('/Product/QuickFacts/Details/Parabens');
    case 'lubricanttype':
      return translate('/Product/QuickFacts/Details/LubricantType');
    case 'glycerines':
      return translate('/Product/QuickFacts/Details/Glycerines');
    case 'condomsafe':
      return translate('/Product/QuickFacts/Details/CondomSafe');
    case 'remotecontroldetails':
      return translate('/Product/QuickFacts/Details/RemoteControlDetails');
    case 'vibetrait':
      return translate('/Product/QuickFacts/Details/VibeTrait');
    case 'vibratorfilter':
      return translate('/Product/QuickFacts/Details/VibratorFilter');
    case 'stiffnes':
      return translate('/Product/QuickFacts/Details/Stiffnes');
    case 'texture':
      return translate('/Product/QuickFacts/Details/Texture');
    case 'openings':
      return translate('/Product/QuickFacts/Details/Openings');
    case 'strapontype':
      return translate('/Product/QuickFacts/Details/StrapOnType');
    case 'clothestrait':
      return translate('/Product/QuickFacts/Details/ClothesTrait');
    case 'effect':
      return translate('/Product/QuickFacts/Details/Effect');
    case 'pussytrait':
      return translate('/Product/QuickFacts/Details/PussyTrait');
    case 'egg':
      return translate('/Product/QuickFacts/Details/Egg');
    case 'cockring':
      return translate('/Product/QuickFacts/Details/CockRing');
    case 'flavour':
      return translate('/Product/QuickFacts/Details/Flavour');
    case 'glidefunction':
      return translate('/Product/QuickFacts/Details/GlideFunction');
    case 'whip':
      return translate('/Product/QuickFacts/Details/Whip');
    case 'shackles':
      return translate('/Product/QuickFacts/Details/Shackles');
    case 'gag':
      return translate('/Product/QuickFacts/Details/Gag');
    case 'stockingsincluded':
      return translate('/Product/QuickFacts/Details/StockingsIncluded');
    case 'pantiesincluded':
      return translate('/Product/QuickFacts/Details/PantiesIncluded');
    case 'bleaching':
      return translate('/Product/QuickFacts/Details/Bleaching');
    case 'drycleaning':
      return translate('/Product/QuickFacts/Details/DryCleaning');
    case 'tumbledryer':
      return translate('/Product/QuickFacts/Details/TumbleDryer');
    case 'basetype':
      return translate('/Product/QuickFacts/Details/BaseType');
    case 'fit':
      return translate('/Product/QuickFacts/Details/Fit');
    case 'washinginstructions':
      return translate('/Product/QuickFacts/Details/WashingInstructions');
    case 'ironing':
      return translate('/Product/QuickFacts/Details/Ironing');
    case 'skucode':
      return translate('/Product/SKU');
    default:
      return '';
  }
};

const Sizes = (props: { sizes: DisplayFieldsPropType }) => (
  <Table>
    <Caption>{translate('/Product/QuickFacts/Size/Heading')}</Caption>
    {props.sizes.map(({ label, value }, index) => (
      <Row key={index}>
        <RowHeader>{label}</RowHeader>
        <RowData>{value}</RowData>
      </Row>
    ))}
  </Table>
);

const Details = (props: { details: DisplayFieldsPropType }) => (
  <Table>
    <Caption>{translate('/Product/QuickFacts/Details/Heading')}</Caption>
    {props.details.map(({ label, value }, index) => (
      <Row key={index}>
        <RowHeader>{label}</RowHeader>
        <RowData>{typeof value === 'boolean' ? value ? <Yes /> : <No /> : value}</RowData>
      </Row>
    ))}
  </Table>
);

const QUICKFACTS_SIZE = 'size';
const QUICKFACTS_DETAILS = 'details';

const baseCss: Style = {
  textAlign: 'center',
  [minMediumMediaQuery]: {
    textAlign: 'left',
    width: '50%',
    paddingLeft: '2rem',
  },
};

export default function QuickFacts(props: PropType) {
  const sizes =
    !!props.quickFacts.length &&
    props.quickFacts
      .filter((quickFact) => quickFact.category === QUICKFACTS_SIZE)
      .map((size) => ({
        label: getLabel(size.id.toLowerCase()),
        value: size.value,
      }));

  const details =
    !!props.quickFacts.length &&
    props.quickFacts
      .filter((quickFact) => quickFact.category === QUICKFACTS_DETAILS)
      .map((detail) => ({
        label: getLabel(detail.id.toLowerCase()),
        value: detail.type === 'string' ? detail.value : detail.value === 'True',
      }));

  return sizes.length > 0 || details.length > 0 ? (
    <Basic css={baseCss}>
      <QuickFactsCaption>{translate('/Product/QuickFacts/Heading')}</QuickFactsCaption>
      <QuickFactsRow>
        {sizes.length > 0 && <Sizes sizes={sizes} />}
        {sizes.length > 0 && details.length > 0 && <ColumnGutter />}
        {details.length > 0 && <Details details={details} />}
      </QuickFactsRow>
    </Basic>
  ) : null;
}
