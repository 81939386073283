import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { translate } from '@avensia/scope';
import { wildwatermelon, gray, pixelsToUnit, minMediumMediaQuery, white, sigma } from 'Shared/Style';
import Price, { Page as PageType, Type as PriceType, Variant as PriceVariant } from 'Pricing/Price';
import { ViewportPropType } from './shared';

type PricesPropType = {
  prices: {
    currency: string;
    current: number;
    discountPercentage: number;
    original: number;
    history: number;
    culture?: string;
  };
};

type PropType = PricesPropType &
  ViewportPropType &
  StyledProps & { productActionbarMobile?: boolean; longPrice?: boolean };

const PriceWrapper = styled.div({
  display: 'flex',
  alignItems: 'flex-end',
});

export default styled(function Prices(props: PropType) {
  const { discountPercentage, currency, current, original, history } = props.prices;
  const hasDiscount = current < original;

  const PriceStyle = {
    color: props.productActionbarMobile && white,
    fontSize: props.productActionbarMobile ? (props.longPrice ? pixelsToUnit(25) : pixelsToUnit(32)) : pixelsToUnit(20),
    lineHeight: props.productActionbarMobile && pixelsToUnit(30),
    [minMediumMediaQuery]: {
      fontSize: pixelsToUnit(35),
    },
  };

  const DiscountedText = styled.span({
    fontSize: pixelsToUnit(10),
    color: props.productActionbarMobile ? white : wildwatermelon,
    textTransform: 'uppercase',
    [minMediumMediaQuery]: {
      fontSize: pixelsToUnit(14),
    },
  });

  const RecommendedPrice = styled(Price, {
    color: props.productActionbarMobile ? white : gray,
    fontSize: pixelsToUnit(10),
    [minMediumMediaQuery]: {
      fontSize: pixelsToUnit(14),
    },
  });

  const RecommendedPriceText = styled.span({
    color: props.productActionbarMobile ? white : gray,
    marginRight: pixelsToUnit(5),
    fontSize: pixelsToUnit(10),
    [minMediumMediaQuery]: {
      fontSize: pixelsToUnit(14),
    },
  });

  const HistoryPriceWrapper = styled.div({
    color: gray,
    fontSize: pixelsToUnit(10),
    [minMediumMediaQuery]: {
      fontSize: sigma,
    },
  });

  const historyPriceStyle = styled(props.isCompact ? { color: white } : { color: gray });

  return (
    <styled.Div css={props.compose()}>
      <Price
        current={current}
        currency={currency}
        variant={props.isCompact ? PriceVariant.Medium : PriceVariant.Huge}
        priceType={hasDiscount ? PriceType.New : PriceType.Regular}
        currentPage={PageType.ProductDetails}
        css={PriceStyle}
      />
      {hasDiscount && (
        <PriceWrapper>
          <RecommendedPriceText>{translate('/Product/RecommendedPrice')}</RecommendedPriceText>
          <RecommendedPrice
            current={original}
            currency={currency}
            variant={PriceVariant.Small}
            priceType={PriceType.Regular}
            currentPage={PageType.ProductDetails}
          />
          {discountPercentage !== 0 && (
            <DiscountedText
              css={{ marginLeft: pixelsToUnit(5), fontSize: pixelsToUnit(10) }}
            >{`(-${discountPercentage}%)`}</DiscountedText>
          )}
        </PriceWrapper>
      )}
      {history !== 0 && (
        <HistoryPriceWrapper css={historyPriceStyle}>
          {translate('/Product/HistoryPrice') + ' '}
          <Price current={history} currency={currency} css={historyPriceStyle} />
        </HistoryPriceWrapper>
      )}
    </styled.Div>
  );
});
