import React from 'react';
import { styled } from '@glitz/react';
import { translate, on } from '@avensia/scope';
import { Variant } from 'Shared/Fields/Select';
import {
  sigma,
  pixelsToUnit,
  thin,
  truncate,
  white,
  lightSilver,
  concrete,
  small,
  transition,
  depth,
  large,
} from 'Shared/Style';
import { Down } from 'Shared/Icon/Arrow';
import Ul from 'Shared/Generic/Ul';
import Button, { Appearance as ButtonAppearance, Variant as ButtonVariant } from 'Shared/Button';
import { MobileWatchableButton } from '../WatchableProduct';
import { pseudo } from '@glitz/core';

type OptionType = {
  name: string;
  value: string;
  woffValue: string;
  inStock?: boolean;
};

type DesktopPropType = {
  value: string;
  woffValue: string;
  label?: string;
  options: OptionType[];
  zIndex?: number;
  onChangeOption?: (value: string, woffValue: string) => any;
  onWatchableOpen?: () => void;
};

type StateType = {
  open?: boolean;
};

const PLACEHOLDER = '-';

export class DesktopDropdown extends React.Component<DesktopPropType, StateType> {
  unsubscribeBodyClose: () => any;

  constructor(props: DesktopPropType) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentDidUpdate(prevProps: DesktopPropType, prevState: StateType) {
    const { open } = this.state;
    if (open !== prevState.open) {
      if (open) {
        this.unsubscribeBodyClose = on('click', () => this.setState({ open: false }));
      } else if (this.unsubscribeBodyClose) {
        this.unsubscribeBodyClose();
        delete this.unsubscribeBodyClose;
      }
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeBodyClose) {
      this.unsubscribeBodyClose();
      delete this.unsubscribeBodyClose;
    }
  }

  toggle = () => this.setState({ open: !this.state.open });

  selectOption(value: string, woffValue: string) {
    if (this.props.onChangeOption) {
      this.props.onChangeOption(value, woffValue);
    }
    this.setState({ open: false });
  }

  render() {
    const variants = this.props.options.map((variant) => ({
      text: variant.name,
      value: variant.value,
      woffValue: variant.woffValue,
      inStock: variant.inStock,
    }));

    const selected = variants.find((o) => o.value === this.props.value);

    return (
      <DesktopBase zIndex={this.props.zIndex}>
        <Button appearance={[ButtonAppearance.Bare, ButtonAppearance.Full]} onClick={this.toggle}>
          <Content>
            <styled.Span>
              <styled.Span css={{ opacity: 0.4 }}>{this.props.label}:</styled.Span>{' '}
              {selected ? selected.text : PLACEHOLDER}
            </styled.Span>
            {this.state.open ? <IconUp /> : <IconDown />}
          </Content>
        </Button>
        <Options css={this.state.open && { transform: 'scaleY(1)' }}>
          {variants.map((option) => (
            <OptionLi key={option.value}>
              <Option
                variant={ButtonVariant.None}
                appearance={[ButtonAppearance.Bare, ButtonAppearance.Full]}
                noRippleEffect
                onClick={() => this.selectOption(option.value, option.woffValue)}
              >
                <OptionContent css={{ width: !option.inStock ? '50%' : '100%' }}>{option.text}</OptionContent>
                {!option.inStock && (
                  <MobileWatchableButton
                    buttonText={translate('/Product/WatchableProduct/WatchableButtonTextMobile')}
                    onPanelOpen={this.props.onWatchableOpen}
                  />
                )}
              </Option>
            </OptionLi>
          ))}
        </Options>
      </DesktopBase>
    );
  }
}

type ZIndexPropType = {
  children: React.ReactNode;
  zIndex?: number;
};

const DesktopBase = ({ zIndex, children }: ZIndexPropType) => (
  <styled.Div
    role="listbox"
    css={{
      alignItems: 'center',
      position: 'relative',
      maxWidth: '100%',
      textTransform: 'uppercase',
      zIndex: zIndex > 0 ? zIndex : 1,
      backgroundColor: white,
      display: 'block',
      border: {
        xy: {
          width: thin,
          style: 'solid',
          color: lightSilver,
        },
      },
      fontSize: sigma,
      letterSpacing: pixelsToUnit(1.3),
      margin: {
        y: large,
        x: 0,
      },
      width: '100%',
    }}
  >
    {children}
  </styled.Div>
);

const IconDown = styled(Down, {
  width: pixelsToUnit(21),
  height: pixelsToUnit(12),
});

const IconUp = styled(IconDown, {
  transform: 'rotate(180deg)',
});

const OptionContent = styled.div({
  marginLeft: small,
  textAlign: 'left',
  width: '50%',
});

const Options = styled(Ul, {
  fontWeight: 'normal',
  position: 'absolute',
  top: '100%',
  right: 0,
  left: 0,
  transform: 'scaleY(0)',
  transformOrigin: 'center top',
  backgroundColor: white,
  listStyleType: 'none',
  ...transition({ property: 'transform' }),
  ...depth(),
});

const OptionLi = styled.li({
  position: 'relative',
  borderBottom: {
    width: pixelsToUnit(1),
    style: 'solid',
    color: '#e4d9eb',
  },
  padding: {
    y: pixelsToUnit(1.5),
  },
  ...pseudo(':last-child', {
    borderBottomStyle: 'none',
  }),
  ':hover': {
    backgroundColor: concrete,
  },
});

const Option = styled(Button, {
  alignItems: 'center',
  display: 'flex',
  fontSize: sigma,
  letterSpacing: pixelsToUnit(1.3),
  height: pixelsToUnit(40),
});

const Content = styled.div({
  alignItems: 'center',
  display: 'flex',
  fontWeight: 'bold',
  justifyContent: 'space-between',
  padding: { y: 0, x: pixelsToUnit(20) },
});

type MobilePropType = {
  value: string;
  onPanelOpen: () => void;
};

export function MobileDropdown(props: MobilePropType) {
  return (
    <MobileSelect variant={Variant.None} appearance={ButtonAppearance.Bare} onClick={props.onPanelOpen}>
      <Text>{props.value}</Text>
      <MobileIconDown />
    </MobileSelect>
  );
}

const MobileSelect = styled(Button, {
  alignItems: 'center',
  border: {
    xy: {
      width: thin,
      style: 'solid',
      color: lightSilver,
    },
  },
  display: 'flex',
  justifyContent: 'space-between',
  flex: {
    grow: 1,
    basis: 1,
  },
  marginRight: pixelsToUnit(20),
});

const Text = styled.span({
  fontSize: sigma,
  letterSpacing: pixelsToUnit(1.1),
  marginLeft: pixelsToUnit(10),
  textTransform: 'uppercase',
  ...truncate(),
});

const MobileIconDown = styled(Down, {
  margin: {
    top: pixelsToUnit(5),
    x: pixelsToUnit(10),
  },
  width: pixelsToUnit(13),
});
