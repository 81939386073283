import React from 'react';
import { styled } from '@glitz/react';
import { pixelsToUnit, minMediumMediaQuery, pageMaxWidth } from 'Shared/Style';
import { Section } from 'Shared/PageLayout';
import CommonPurchaseCombinationViewModelType from 'Product/CommonPurchaseCombination/CommonPurchaseCombinationViewModel.type';
import CommonPurchaseCombinationItem from './CommonPurchaseCombinationItem';

type RequiredPropType = {
  description?: Scope.XhtmlString;
  isCompact: boolean;
  commonPurchaseCombinationList: CommonPurchaseCombinationViewModelType[];
  addAllItemsToCart: (products: any) => void;
};

type PropType = RequiredPropType & {
  forwardedRef: React.Ref<HTMLDivElement>;
};

function CommonPurchaseCombination(props: PropType) {
  const { commonPurchaseCombinationList } = props;

  return (
    <Base>
      <Wrapper>
        {commonPurchaseCombinationList.map((combo, i) => {
          return <CommonPurchaseCombinationItem key={i} combo={combo} addAllItemsToCart={props.addAllItemsToCart} />;
        })}
      </Wrapper>
    </Base>
  );
}

export default React.forwardRef(function WithForwardedRefMidSection(
  props: RequiredPropType,
  ref: React.Ref<HTMLDivElement>,
) {
  return <CommonPurchaseCombination {...props} forwardedRef={ref} />;
});

const Base = styled(Section, {
  display: 'flex',
  [minMediumMediaQuery]: {
    maxWidth: pixelsToUnit(pageMaxWidth),
    padding: {
      x: 0,
    },
  },
});

const Wrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
});
