import React from 'react';
import { styled } from '@glitz/react';
import { minSmallMediaQuery, pixelsToUnit } from 'Shared/Style';

type PropType = {
  name: string;
};

const Name = styled.h1({
  fontSize: '1.6rem',
  fontWeight: 400,
  lineHeight: 'normal',
  textTransform: 'uppercase',
  [minSmallMediaQuery]: {
    marginBottom: pixelsToUnit(6),
    fontSize: '1.8rem',
  },
});

export default (props: PropType) => <Name>{props.name}</Name>;
