import { YouTubeAPI } from 'Shared/Video/youtube.ts';

let youtubeIsInit: Promise<YouTubeAPI>;
export function ensureYTInit(): Promise<YouTubeAPI> {
  if (!youtubeIsInit) {
    youtubeIsInit = initYoutube();
  }
  return youtubeIsInit;
}

function initYoutube(): Promise<YouTubeAPI> {
  const iframeApi = new Promise<YouTubeAPI>(resolve => {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    tag.id = 'YTscript';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    window['onYouTubeIframeAPIReady'] = () => {
      resolve(window['YT']);
    };
  });

  return iframeApi;
}
