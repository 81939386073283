import React from 'react';
import { styled, StyledComponent, StyledProps } from '@glitz/react';

type PropType = {
  rating: number;
  star: StyledComponent<any>;
  starOutline: StyledComponent<any>;
} & StyledProps;

const RATINGS = [1, 2, 3, 4, 5];
export default styled((props: PropType) => (
  <styled.Div css={props.compose()}>
    {RATINGS.map(rating => (rating <= props.rating ? <props.star key={rating} /> : <props.starOutline key={rating} />))}
  </styled.Div>
));
