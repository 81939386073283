import React, { useState } from 'react';
import { epiPropertyValue } from '@avensia/scope-episerver';
import Button from 'Shared/Button';
import { translate } from '@avensia/scope';
import CommonPurchaseCombinationViewModelType from 'Product/CommonPurchaseCombination/CommonPurchaseCombinationViewModel.type';
import { styled } from '@glitz/react';
import { minMediumMediaQuery, minLargeMediaQuery, minHugeMediaQuery, minSmallMediaQuery, white } from 'Shared/Style';
import CommonPurchaseCombinationProduct from './CommonPurchaseCombinationProduct';

type RequiredPropType = {
  combo: CommonPurchaseCombinationViewModelType;
  addAllItemsToCart: (products: any) => void;
};

type PropType = RequiredPropType;

export default function CommonPurchaseCombinationItem(props: PropType) {
  let products: any[] = [];
  let originalPrices: any[] = [];
  const [comboTotal, setComboTotal] = useState(props.combo.price.current);
  const [deSelectedItems, setDeselectedItems] = useState([]);
  const [selectedItemsCount, setSelectedItemsCount]: any = useState(props.combo.productList.length);
  const [deselectedTotalDiscount, setDeselectedTotalDiscount]: any = useState(0);

  const decreaseTotal = (price: any) => {
    const copy = comboTotal - price;
    setComboTotal(copy);
  };

  const increaseTotal = (price: any) => {
    const copy = comboTotal + price;
    setComboTotal(copy);
  };

  const handleDeselect = (data: any) => {
    const currentDeselectedTotalDiscount = deselectedTotalDiscount;
    const deselectedDiscount = data.originalPrice - data.currentPrice;
    const newTotalDiscount = currentDeselectedTotalDiscount + deselectedDiscount;
    setDeselectedTotalDiscount(newTotalDiscount);
    const deselected = deSelectedItems;
    deselected.push(data);
    setDeselectedItems(deselected);
    decreaseTotal(data.currentPrice);
    const diff = props.combo.productList.length - deSelectedItems.length;
    setSelectedItemsCount(diff);
  };

  const handleReselect = (data: any) => {
    const currentDeselectedTotalDiscount = deselectedTotalDiscount;
    const deselectedDiscount = data.originalPrice - data.currentPrice;
    const newTotalDiscount = currentDeselectedTotalDiscount - deselectedDiscount;
    setDeselectedTotalDiscount(newTotalDiscount);
    const deselected = deSelectedItems;
    deselected.forEach((item) => {
      if (item.code === data.code) {
        const index = deselected.indexOf(item);
        if (index > -1) {
          deselected.splice(index, 1);
        }
      }
    });
    setDeselectedItems(deselected);
    increaseTotal(data.currentPrice);
    setSelectedItemsCount(selectedItemsCount + 1);
  };

  const handleAddToCart = (item: any) => {
    item.forEach((product: any) => {
      deSelectedItems.forEach((deselected) => {
        if (product.code === deselected.code) {
          const index = products.indexOf(product);
          if (index > -1) {
            products.splice(index, 1);
          }
        }
      });
    });
    props.addAllItemsToCart(products);
    setSelectedItemsCount(selectedItemsCount);
  };

  return (
    <CombinationOuterContainer>
      <CombinationContainer>
        {props.combo.productList.map((product: any, j: any) => {
          const variationCode = epiPropertyValue(product.variation.code);

          let data = {
            code: variationCode,
            ticket: product.ticket,
            currentPrice: product.price.current,
            originalPrice: product.price.original,
          };
          products.push(data);
          const discount = product.price.original - product.price.current;
          originalPrices.push(discount);
          return (
            <React.Fragment key={j}>
              <CommonPurchaseCombinationProduct
                product={product}
                handleDeselect={handleDeselect}
                handleReselect={handleReselect}
              />
              {props.combo.productList.length - 1 !== j && (
                <PlusWrapper>
                  <Plus>+</Plus>
                </PlusWrapper>
              )}
            </React.Fragment>
          );
        })}
      </CombinationContainer>
      <EqualTo>=</EqualTo>
      <PriceButtonContainer>
        <ComboPriceHeader>
          {selectedItemsCount === 2
            ? translate('/Product/BuyBothFor')
            : selectedItemsCount <= 1
            ? ''
            : translate('/Product/BuyAllFor')}
        </ComboPriceHeader>
        <ComboPrice>
          {comboTotal.toFixed(0)}
          {translate('/Product/Currency')}
        </ComboPrice>
        {originalPrices.reduce(function (a, b) {
          return a + b;
        }) -
          deselectedTotalDiscount >
          0 && (
          <SaveSum>
            {translate('/Product/AndSave') + ' '}
            {originalPrices
              .reduce(function (a, b) {
                return a + b;
              })
              .toFixed(0) - deselectedTotalDiscount.toFixed(0)}
            {translate('/Product/Currency')}
          </SaveSum>
        )}
        <StyledButton onClick={() => handleAddToCart(products)} disabled={selectedItemsCount < 1}>
          {selectedItemsCount === 2
            ? translate('/Product/BuyBoth') + ' ' + selectedItemsCount
            : selectedItemsCount <= 1
            ? translate('/Product/Buy')
            : translate('/Product/BuyAll')}
        </StyledButton>
      </PriceButtonContainer>
    </CombinationOuterContainer>
  );
}
const CombinationContainer = styled.div({
  [minLargeMediaQuery]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const CombinationOuterContainer = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'white',
  margin: {
    y: '1rem',
    x: '1rem',
  },
  padding: { xy: 0 },
  [minHugeMediaQuery]: {
    padding: { xy: '2rem' },
    margin: {
      y: '1rem',
      x: 0,
    },
  },
});

const StyledButton = styled(Button, {
  width: '7rem',
  height: '2.5rem',
  display: 'flex',
  justifyContent: 'center',
  fontSize: '1rem',
  lineHeight: '2.5rem',
  color: white,
  [minSmallMediaQuery]: {
    width: '9rem',
    height: '3.5rem',
    lineHeight: '3.5rem',
  },
  [minMediumMediaQuery]: {
    width: '12rem',
  },
});

const PriceButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'center',
  marginLeft: '0.5rem',
  [minSmallMediaQuery]: {
    margin: 0,
  },
});

const ComboPriceHeader = styled.div({
  fontWeight: 'bold',
  fontSize: '0.8rem',
  [minSmallMediaQuery]: {
    fontSize: '1rem',
  },
  [minMediumMediaQuery]: {
    fontSize: '1.2rem',
  },
});

const SaveSum = styled(ComboPriceHeader, {
  marginBottom: '1rem',
});

const ComboPrice = styled.div({
  fontSize: '1.8rem',
  lineHeight: '2.5rem',
  fontWeight: 'bold',
  [minSmallMediaQuery]: {
    fontSize: '2.6rem',
    lineHeight: '3rem',
  },
  [minMediumMediaQuery]: {
    fontSize: '3rem',
    lineHeight: '4rem',
  },
});

const Plus = styled.div({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: (theme) => theme.buyButtonProductcardDesktop,
  color: 'white',
  borderRadius: '100%',
  alignSelf: 'center',
  justifyContent: 'center',
  width: '2rem',
  height: '2rem',
  fontSize: '2rem',
  [minSmallMediaQuery]: {
    width: '3rem',
    height: '3rem',
    fontSize: '3rem',
    padding: { x: '1rem', bottom: 0 },
  },
  [minMediumMediaQuery]: {
    width: '3rem',
    height: '3rem',
    fontSize: '4rem',
    padding: { x: 0, bottom: '0.3rem' },

    [minHugeMediaQuery]: {
      margin: { xy: '1rem' },
    },
  },
});

const PlusWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  [minHugeMediaQuery]: {
    display: 'block',
  },
});

const EqualTo = styled(Plus, {
  width: '2.5rem',
  height: '2.5rem',
  fontSize: '2.5rem',
  margin: { x: 0, y: 0 },
  padding: { x: '1rem', bottom: 0 },
  [minSmallMediaQuery]: {
    width: '4rem',
    height: '4rem',
    fontSize: '4rem',
    margin: { x: '0.2rem', y: '0.2rem' },
  },
  [minMediumMediaQuery]: {
    width: '3.5rem',
    height: '3.5rem',
    fontSize: '3.5rem',
  },
});
