import React, { ReactNode } from 'react';
import { isIOS, Breakpoint } from '@avensia/scope';
import { styled } from '@glitz/react';
import {
  minTinyMediaQuery,
  pixelsToUnit,
} from 'Shared/Style';
import connect from 'Shared/connect';
import Icon from 'Shared/Icon/Close';
import freezeScroll from 'Shared/freeze-scroll';
import * as style from 'Shared/Style';


type RequiredPropType = {
  children: ReactNode;
  close: () => void;
};

type ConnectStateType = {
  currentBreakpoint: number;
};

type PropType = RequiredPropType & ConnectStateType;
class Modal extends React.Component<PropType> {
  unfreezeScroll: () => void;
  componentDidMount() {
    this.scrollFreeze();
    if (isIOS()) {
      document.body.setAttribute('style', 'position: fixed');
    }
  }

  componentWillUnmount() {
    this.scrollFreeze();
    if (isIOS()) {
      document.body.removeAttribute('style');
    }
  }

  scrollFreeze() {
    if (this.props.currentBreakpoint < Breakpoint.Medium) {
      if (this.unfreezeScroll) {
        this.unfreezeScroll();
      }
      this.unfreezeScroll = !this.unfreezeScroll ? freezeScroll() : null;
    }
  }

  render() {
    return (
      <Base>
        <Close onClick={this.props.close} />
        {this.props.children}
      </Base>
    );
  }
}

export default connect(
  (state): ConnectStateType => ({
    currentBreakpoint: state.currentBreakpoint,
  }),
)(Modal);

const Base = styled.div({
  position: 'fixed',
  zIndex: style.ZIndex.Modal,
  top: 0, bottom: 0, left: 0, right: 0,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: style.white,
  overflow: 'hidden',
});

const Close = styled(Icon, {
  display: 'block',
  position: 'absolute',
  zIndex: style.ZIndex.Overlay,
  cursor: 'pointer',
  top: pixelsToUnit(10),
  right: pixelsToUnit(10),
  height: pixelsToUnit(30),
  width: pixelsToUnit(30),
  [minTinyMediaQuery]: {
    top: pixelsToUnit(20),
    right: pixelsToUnit(20),
    height: pixelsToUnit(40),
    width: pixelsToUnit(40),
  },
});

