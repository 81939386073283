import React, { ReactNode } from 'react';
import { isIOS, isTouch } from '@avensia/scope';
import * as style from 'Shared/Style';
import { Style } from '@glitz/type';
import { styled, StyledProps } from '@glitz/react';
import PrismaZoom, { PrismaComponent } from 'react-prismazoom';
import { pixelsToUnit } from 'Shared/Style';
import Image, { Preset as ImagePreset } from 'Shared/Image';
import Button, { Variant as ButtonVariant, Appearance as ButtonAppearance } from 'Shared/Button';
import IconZoomIn from 'Shared/Icon/ZoomIn';
import IconZoomOut from 'Shared/Icon/ZoomOut';
import IconClose from 'Shared/Icon/Close';

type PropType = {
  imageUrl: string;
  cloakedImages?: boolean;
  preset?: ImagePreset;
};

type StateType = {
  zoom: number;
  modalOpen: boolean;
};

export default class MobileModal extends React.Component<PropType, StateType> {
  //Needs to be any, else we get this error when using the ref property:
  //Type 'RefObject<PrismaComponent>' is not assignable to type '(string & RefObject<PrismaComponent>) | (((instance: Component<PrismaProps, any, any>) => void) & RefObject<PrismaComponent>) | (RefObject<...> & RefObject<...>)'.
  private prismaZoom: any = React.createRef<PrismaComponent>();

  constructor(props: PropType) {
    super(props);
    this.state = {
      zoom: 2,
      modalOpen: false,
    };
  }

  componentWillUnmount() {
    document.body.removeAttribute('style');
  }

  openModal = () => {
    //This is needed for react-prismazoom to calculate the correct container height:
    if (isIOS()) {
      document.body.setAttribute('style', 'overflow: hidden; height: 100vh; position: fixed');
    } else {
      document.body.setAttribute('style', 'overflow: hidden; height: 100vh');
    }

    this.setState({
      modalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalOpen: false,
    });
    document.body.removeAttribute('style');
  };

  onZoomChange = (zoom: number) => {
    this.setState({ zoom });
  };

  zoomOut = () => {
    this.prismaZoom.current.zoomOut(1);
  };

  zoomIn = () => {
    this.prismaZoom.current.zoomIn(1);
  };

  render() {
    const { imageUrl, preset } = this.props;
    return this.state.modalOpen ? (
      <Modal>
        <ZoomContainer onClick={this.closeModal}>
          <PrismaZoom
            minZoom={1}
            maxZoom={3}
            scrollVelocity={0}
            doubleTouchMaxDelay={0}
            onZoomChange={this.onZoomChange}
            ref={this.prismaZoom}
          >
            <Image
              preset={!imageUrl.includes('.gif') ? preset || ImagePreset.Small : undefined}
              src={imageUrl}
              css={{ display: 'block' }}
            />
          </PrismaZoom>
        </ZoomContainer>
        <ModalIconContainer>
          <IconButton onClick={this.zoomOut}>
            <IconZoomOut />
          </IconButton>
          <IconButton onClick={this.zoomIn}>
            <IconZoomIn />
          </IconButton>
          <IconButton onClick={this.closeModal}>
            <IconClose />
          </IconButton>
        </ModalIconContainer>
      </Modal>
    ) : (
      <IconContainer>
        <IconButton onClick={this.openModal}>
          <IconZoomIn />
        </IconButton>
      </IconContainer>
    );
  }
}

const Modal = styled.div({
  position: 'fixed',
  zIndex: style.ZIndex.Modal,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: style.white,
});

const ZoomContainer = styled.div({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
});

const IconContainer = styled.div({
  position: 'absolute',
  zIndex: 1,
  top: '1rem',
  right: '2rem',
  height: pixelsToUnit(22),
});

const ModalIconContainer = styled.div({
  position: 'fixed',
  zIndex: style.ZIndex.Modal + 1,
  display: 'flex',
  top: pixelsToUnit(20),
  right: pixelsToUnit(20),
  height: pixelsToUnit(22),
});

type ButtonPropType = {
  onClick: () => void;
  children: ReactNode;
};

const IconButton = styled((props: ButtonPropType & StyledProps) => {
  const { compose, ...restProps } = props;
  const css: Style = {
    width: pixelsToUnit(40),
    height: pixelsToUnit(40),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: pixelsToUnit(20),
    backgroundColor: style.white,
    borderRadius: '50%',
    padding: { xy: pixelsToUnit(5) },
    marginLeft: pixelsToUnit(10),
    userSelect: 'none',
    cursor: 'pointer',
    opacity: 0.3,
    ...style.transition({ property: 'opacity' }),
    pointerEvents: 'auto',
    ...(!isTouch() && {
      ':hover': {
        opacity: 0.6,
      },
    }),
  };

  function handleClick() {
    restProps.onClick();
  }

  return (
    <styled.Div css={compose(css)}>
      <Button variant={ButtonVariant.None} appearance={ButtonAppearance.Bare} onClick={handleClick} noRippleEffect>
        {restProps.children}
      </Button>
    </styled.Div>
  );
});
