import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { replaceState, translate, on } from '@avensia/scope';
import { Variant } from 'Shared/Fields/Select';
import { SizeGuide } from './../ProductDetails';
import SizeGuidePageViewModelType from 'SizeGuide/SizeGuideViewModel.type';
import VariationLinkViewModelType from 'Product/VariationLinkViewModel.type';
import {
  sigma,
  pixelsToUnit,
  thick,
  thin,
  snuff,
  truncate,
  alto,
  white,
  lightSilver,
  zeta,
  ZIndex,
  concrete,
  small,
  transition,
  depth,
  large,
  minTinyMediaQuery,
  minMediumMediaQuery,
} from 'Shared/Style';
import { Down } from 'Shared/Icon/Arrow';
import Ul from 'Shared/Generic/Ul';
import RippleEffect, { rippleContainerStyle } from 'Shared/RippleEffect';
import Button, { Appearance as ButtonAppearance, Variant as ButtonVariant } from 'Shared/Button';
import Close from 'Shared/Icon/Close';
import Panel from 'Shared/Panel';
import { MobileWatchableButton } from '../WatchableProduct';
import { media, pseudo } from '@glitz/core';

type DesktopPropType = {
  value: string;
  label?: string;
  options: VariationLinkViewModelType[];
  onChangeOption?: (value: string) => any;
  onWatchableOpen?: () => void;
};

type StateType = {
  open?: boolean;
};

const PLACEHOLDER = '-';

export class DesktopSelector extends React.Component<DesktopPropType, StateType> {
  unsubscribeBodyClose: () => any;

  constructor(props: DesktopPropType) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentDidUpdate(prevProps: DesktopPropType, prevState: StateType) {
    const { open } = this.state;
    if (open !== prevState.open) {
      if (open) {
        this.unsubscribeBodyClose = on('click', () => this.setState({ open: false }));
      } else if (this.unsubscribeBodyClose) {
        this.unsubscribeBodyClose();
        delete this.unsubscribeBodyClose;
      }
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeBodyClose) {
      this.unsubscribeBodyClose();
      delete this.unsubscribeBodyClose;
    }
  }

  toggle = () => this.setState({ open: !this.state.open });

  selectOption(value: string) {
    if (this.props.onChangeOption) {
      this.props.onChangeOption(value);
    }
    this.setState({ open: false });
  }

  render() {
    const variants = this.props.options.map((variant) => ({
      text: variant.name,
      value: variant.url,
      inStock: variant.inStock,
    }));

    const selected =
      variants.find((o) => o.value === this.props.value) ||
      variants.find(
        (o) =>
          o.value.substring(o.value.lastIndexOf('/')) === this.props.value.substring(this.props.value.lastIndexOf('/')),
      );

    return (
      <DesktopBase role="listbox">
        <Button appearance={[ButtonAppearance.Bare, ButtonAppearance.Full]} onClick={this.toggle}>
          <Content>
            <styled.Span>
              <styled.Span css={{ opacity: 0.4 }}>{this.props.label}:</styled.Span>{' '}
              {selected ? selected.text : PLACEHOLDER}
            </styled.Span>
            {this.state.open ? <IconUp /> : <IconDown />}
          </Content>
        </Button>
        <Options css={this.state.open && { transform: 'scaleY(1)' }}>
          {variants.map((option) => (
            <OptionLi key={option.value}>
              <Option
                variant={ButtonVariant.None}
                appearance={[ButtonAppearance.Bare, ButtonAppearance.Full]}
                noRippleEffect
                onClick={() => this.selectOption(option.value)}
              >
                <OptionContent css={{ width: !option.inStock ? '50%' : '100%' }}>{option.text}</OptionContent>
                {!option.inStock && (
                  <MobileWatchableButton
                    buttonText={translate('/Product/WatchableProduct/WatchableButtonTextMobile')}
                    onPanelOpen={this.props.onWatchableOpen}
                  />
                )}
              </Option>
            </OptionLi>
          ))}
        </Options>
      </DesktopBase>
    );
  }
}

const DesktopBase = styled.div({
  alignItems: 'center',
  position: 'relative',
  maxWidth: '100%',
  textTransform: 'uppercase',
  zIndex: 3,
  backgroundColor: white,
  display: 'block',
  border: {
    xy: {
      width: thin,
      style: 'solid',
      color: lightSilver,
    },
  },
  fontSize: sigma,
  letterSpacing: pixelsToUnit(1.3),
  margin: {
    y: large,
    x: 0,
  },
  width: '100%',
});

const IconDown = styled(Down, {
  width: pixelsToUnit(21),
  height: pixelsToUnit(12),
});

const IconUp = styled(IconDown, {
  transform: 'rotate(180deg)',
});

const OptionContent = styled.div({
  marginLeft: small,
  textAlign: 'left',
  width: '50%',
});

const Options = styled(Ul, {
  fontWeight: 'normal',
  position: 'absolute',
  top: '100%',
  right: 0,
  left: 0,
  transform: 'scaleY(0)',
  transformOrigin: 'center top',
  backgroundColor: white,
  listStyleType: 'none',
  ...transition({ property: 'transform' }),
  ...depth(),
});

const OptionLi = styled.li({
  position: 'relative',
  borderBottom: {
    width: pixelsToUnit(1),
    style: 'solid',
    color: '#e4d9eb',
  },
  padding: {
    y: pixelsToUnit(1.5),
  },
  ...pseudo(':last-child', {
    borderBottomStyle: 'none',
  }),
  ':hover': {
    backgroundColor: concrete,
  },
});

const Option = styled(Button, {
  alignItems: 'center',
  display: 'flex',
  fontSize: sigma,
  letterSpacing: pixelsToUnit(1.3),
  height: pixelsToUnit(40),
});

const Content = styled.div({
  alignItems: 'center',
  display: 'flex',
  fontWeight: 'bold',
  justifyContent: 'space-between',
  padding: { y: 0, x: pixelsToUnit(20) },
});



type MobilePropType = {
  value: string;
  onPanelOpen: () => void;
  longVariantName?: boolean;
};

export function MobileSelector(props: MobilePropType) {
  return (
    <MobileSelect variant={Variant.None} appearance={ButtonAppearance.Bare} onClick={props.onPanelOpen}>
      <MobileIconUp />
      <Text css={props.longVariantName && { fontSize: pixelsToUnit(12) }}>{props.value}</Text>
    </MobileSelect>
  );
}

export function MobileSelectorActionbar(props: MobilePropType) {
  return (
    <MobileSelectActionbar variant={Variant.None} appearance={ButtonAppearance.Bare} onClick={props.onPanelOpen}>
      <TextActionbar css={props.longVariantName && { fontSize: pixelsToUnit(12) }}>{props.value}</TextActionbar>
    </MobileSelectActionbar>
  );
}

const MobileSelect = styled(Button, {
  display: 'flex',
  alignItems: 'center',
  justifySelf: 'center',
  flexDirection: 'column',
  borderRadius: '100%',
  width: '3.5rem',
  height: '3.5rem',
  paddingTop: '0.6rem',
  marginTop: pixelsToUnit(4),
  border: {
    xy: {
      width: thin,
      style: 'solid',
      color: lightSilver,
    },
  },
  flex: {
    grow: 1,
    basis: 1,
  },
  marginRight: pixelsToUnit(5),
  [minTinyMediaQuery]: {
    marginRight: pixelsToUnit(20),
  },
});

const MobileSelectActionbar = styled(Button, {
  padding: {x: pixelsToUnit(3), y: 0},
  minWidth: '2.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: (theme) => theme.buyButtonProductPageMobileText,
  color: white,
  marginRight: pixelsToUnit(5),
  border: {
    xy: {
      width: thick,
      style: 'solid',
      color: white,
    },
  },
  [minTinyMediaQuery]: {
    marginRight: pixelsToUnit(20),
  },
});

const Text = styled.span({
  textTransform: 'uppercase',
  fontSize: pixelsToUnit(20),
  lineHeight: '2.2rem',
  ...truncate(),
});

const TextActionbar = styled(Text,{
  fontSize: pixelsToUnit(25),
  fontWeight: 800,
  lineHeight: 'unset',
});

const MobileIconUp = styled(Down, {
  position: 'absolute',
  top: '0.5rem',
  left: '0.65rem',
  transform: 'rotate(180deg)',
  fontSize: pixelsToUnit(5),
  marginTop: pixelsToUnit(2),
  color: lightSilver,
  margin: {
    x: pixelsToUnit(10),
  },
  width: pixelsToUnit(13),
});

type PanelPropType = {
  value: {
    name: string;
    code: string;
  };
  options: VariationLinkViewModelType[];
  open: boolean;
  onPanelClose: () => void;
  sizeguide: SizeGuidePageViewModelType;
  onWatchableOpen?: () => void;
};

export function VariantPanel(props: PanelPropType) {
  return (
    <Panel
      css={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
      isOpen={props.open}
      maxHeight="100%"
      maxWidth="100%"
      onClose={props.onPanelClose}
    >
      <CompactBase>
        <TopBase>
          {!!props.sizeguide && (
            <SizeGuideWrapper>
              <SizeGuide sizeGuide={props.sizeguide} />
            </SizeGuideWrapper>
          )}
        </TopBase>
        <PanelBase>
          <Header>
            <Heading>{translate('/Product/WatchableProduct/ChooseVariant')}</Heading>

            <ActionButton onClick={props.onPanelClose} variant={ButtonVariant.None} appearance={ButtonAppearance.Bare}>
              <Close />
            </ActionButton>
          </Header>
          <VariantList bare>
            {props.options.map((variant) => {
              const isCurrent = props.value.code === variant.code;
              return (
                <PanelItem
                  css={{
                    cursor: isCurrent ? 'default' : 'pointer',
                    backgroundColor: isCurrent ? alto : white,
                  }}
                  key={variant.code}
                  variant={variant}
                  onItemClick={isCurrent ? undefined : replaceState}
                  onWatchableOpen={props.onWatchableOpen}
                />
              );
            })}
          </VariantList>
        </PanelBase>
      </CompactBase>
    </Panel>
  );
}

type PanelItemPropType = {
  variant: VariationLinkViewModelType;
  onItemClick: (variantUrl: string) => void | undefined;
  onWatchableOpen: () => void;
} & StyledProps;

const PanelItem = styled((props: PanelItemPropType) => {
  return (
    <RippleEffect>
      {(ripples: Array<React.ReactElement<any>>, push: (e: React.MouseEvent<HTMLElement>) => Promise<void>) => (
        <Li
          css={props.compose(rippleContainerStyle)}
          // tslint:disable-next-line:jsx-no-lambda
          onClick={(e) => {
            push(e);
          }}
        >
          <Item
            // tslint:disable-next-line:jsx-no-lambda
            onClick={(e) => {
              setTimeout(() => {
                if (props.onItemClick) {
                  props.onItemClick(props.variant.url);
                }
              }, 250);
            }}
          >
            {props.variant.name}
          </Item>
          {!props.variant.inStock && (
            <CustomMobileButton
              buttonText={translate('/Product/WatchableProduct/WatchableButtonTextMobile')}
              onPanelOpen={props.onWatchableOpen}
            />
          )}
          {ripples}
        </Li>
      )}
    </RippleEffect>
  );
});

const VariantList = styled(Ul, {
  overflowY: 'auto',
});

const Li = styled.li({
  alignItems: 'center',
  display: 'flex',
  borderBottom: {
    width: thin,
    style: 'solid',
    color: snuff,
  },
  height: pixelsToUnit(50),
  padding: {
    x: pixelsToUnit(20),
    y: pixelsToUnit(12),
  },
});

const Item = styled.span({
  flexGrow: 1,
  textTransform: 'uppercase',
  ...truncate(),
});

const CompactBase = styled.div({
  height: '100%',
  position: 'fixed',
  top: 0,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  zIndex: ZIndex.Panel,
});

const TopBase = styled.div({
  position: 'relative',
  display: 'flex',
  flexGrow: 1,
});

const PanelBase = styled.div({
  backgroundColor: theme => theme.productMobileActionbar,
  minHeight: '50%',
  maxHeight: '80%',
  position: 'static',
  display: 'flex',
  flexDirection: 'column',
  [minMediumMediaQuery]: {
    backgroundColor: white,
  },
});

const Header = styled.header({
  alignItems: 'center',
  backgroundColor: (theme) => theme.productMobileActionbar,
  color: white,
  display: 'flex',
  flexShrink: 0,
  height: pixelsToUnit(50),
  paddingLeft: pixelsToUnit(20),
  ...media(minMediumMediaQuery, {
    color: (theme) => theme.cardBackgroundColor,
  }),
});

const Heading = styled.div({
  flexGrow: 1,
  fontSize: zeta,
  fontWeight: 'bold',
  textTransform: 'uppercase',
});

const ActionButton = styled(Button, {
  paddingRight: pixelsToUnit(20),
});

const CustomMobileButton = styled(MobileWatchableButton, {
  flexGrow: 0,
});

const SizeGuideWrapper = styled.div({
  position: 'absolute',
  bottom: 0,
  right: pixelsToUnit(10),
});
