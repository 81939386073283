import React from 'react';
import { styled } from '@glitz/react';
import { small, pixelsToUnit, minMediumMediaQuery, pageMaxWidth, white } from 'Shared/Style';
import { Section } from 'Shared/PageLayout';
import Reviews from 'Product/Reviews';
import ReviewViewModelType from 'Reviews/Models/ReviewViewModel.type';

type ReviewsPropType = {
  catalogId: number;
  language: string;
  productCode: string;
  averageRating: number;
  topReviews: ReviewViewModelType[];
  totalReviews: number;
  productPageUrl: string;
  isExpandReviews: boolean;
  toggleReviewPanel: () => void;
};

type RequiredPropType = {
  reviews: ReviewsPropType;
  isCompact: boolean;
};

type PropType = RequiredPropType;

export default function ReviewSection(props: PropType) {
  const { reviews } = props;
  return (
    <Base>
      <ProductReviews {...reviews} />
    </Base>
  );
}

const Base = styled(Section, {
  backgroundColor: white,
  padding: {
    x: small,
    y: small,
  },
  [minMediumMediaQuery]: {
    maxWidth: pixelsToUnit(pageMaxWidth),
    padding: {
      xy: pixelsToUnit(10),
    },
  },
});

const rightColumnStyled = styled({
  [minMediumMediaQuery]: {
    flexGrow: 0,
    flexShrink: 0,
    paddingLeft: '1.5rem',
  },
});

const ProductReviews = rightColumnStyled(Reviews, {
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
});
