import React from 'react';
import { getVideoUrl } from 'Shared/Blocks/Video/VideoBlock';
import { styled, StyledProps } from '@glitz/react';
import connect from 'Shared/connect';
import { Player } from 'vimeo__player';
import {
  minMicroMediaQuery,
  minSmallMediaQuery,
  minMediumMediaQuery,
  minHugeMediaQuery,
  minLargeMediaQuery,
  pixelsToUnit,
} from 'Shared/Style';

type ConnectStateType = {
  currentBreakpoint: number;
};
type PropTypes = StyledProps &
  ConnectStateType & {
    key?: string;
    index: number;
    videoId: string;
    options?: string;
    isCurrent: boolean;
  } & ConnectStateType;

class ProductMedia extends React.Component<PropTypes, {}> {
  player: Player;
  playerRef: HTMLIFrameElement;

  componentDidMount() {
    const VimeoPlayer = require('@vimeo/player');
    this.player = new VimeoPlayer.default(this.playerRef);
  }

  componentDidUpdate(prevProps: PropTypes) {
    if (prevProps.isCurrent && !this.props.isCurrent) {
      this.player.pause();
    }
  }

  togglePlayer = () => {
    if (this.player) {
      this.player.getPaused().then((val) => {
        if (val) {
          this.player.play();
        } else {
          this.player.pause();
        }
      });
    }
  };

  render() {
    const isMobile = this.props.currentBreakpoint < 4;
    const { videoId, index } = this.props;
    const addMute = isMobile ? `${this.props.options ? '&' : '?'}muted=1` : '';
    return (
      <VideoContainer>
        <LayerWrapper>
          <VideoIframe
            loading="lazy"
            id={`vimeo-video-${index}`}
            frameBorder="0"
            allowFullScreen
            data-allow="autoplay"
            src={getVideoUrl(videoId, 'vimeo') + this.props.options + addMute}
            ref={(el: HTMLIFrameElement) => {
              this.playerRef = el;
            }}
          />
          {isMobile && <Layer onClick={this.togglePlayer} />}
        </LayerWrapper>
      </VideoContainer>
    );
  }
}
export default styled(
  connect((state) => ({
    currentBreakpoint: state.currentBreakpoint,
  }))(ProductMedia),
);

const Layer = styled.div({
  position: 'absolute',
  left: 0,
  width: '80%',
  height: '50%',
  top: '25%',
  zIndex: 1,
  [minSmallMediaQuery]: {
    width: '92%',
    height: '70%',
    top: '15%',
  },
});
const LayerWrapper = styled.div({
  width: '100%',
  position: 'relative',
});
const VideoContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

const VideoIframe = styled.iframe({
  height: '180px',
  width: '100%',
  [minHugeMediaQuery]: {
    height: pixelsToUnit(400),
  },
  [minLargeMediaQuery]: {
    height: pixelsToUnit(350),
  },
  [minMediumMediaQuery]: {
    height: pixelsToUnit(250),
  },
  [minSmallMediaQuery]: {
    height: pixelsToUnit(400),
  },
  [minMicroMediaQuery]: {
    height: pixelsToUnit(200),
  },
});
