import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import {
  minMediumMediaQuery,
  pixelsToUnit,
  thin,
  epsilon,
  sigma,
  lightGrey,
  snuff,
  kappa,
  mega,
  minTinyMediaQuery,
} from 'Shared/Style';
import { Basic } from 'Shared/PageLayout';
import CloseIcon from 'Shared/Icon/Close';
import Email from 'Shared/Icon/Email';
import Panel from 'Shared/Panel';
import Modal from './Modal';
import { translate } from '@avensia/scope';

type WatchableButtonPropType = {
  buttonText: string;
  onPanelOpen: () => void;
} & StyledProps;

export const WatchableButton = styled((props: WatchableButtonPropType) => (
  <Base>
    <AddWatch onClick={props.onPanelOpen}>
      <ButtonText>{props.buttonText}</ButtonText>
      <Wrapper>
        <EmailIcon />
      </Wrapper>
    </AddWatch>
  </Base>
));

export const WatchableButtonActionbar = styled((props: WatchableButtonPropType) => (
  <BaseActionbar>
    <AddWatch onClick={props.onPanelOpen}>
      <WrapperActionbar>
      {translate('/Product/WatchableProduct/WatchableButtonTextMobile')}
      </WrapperActionbar>
    </AddWatch>
  </BaseActionbar>
));

export const MobileWatchableButton = styled((props: WatchableButtonPropType) => (
  <MobileBase css={props.compose()} onClick={props.onPanelOpen}>
    <OutOfStock>{translate('/Product/OutOfStock')}</OutOfStock>
    <EmailIcon css={{ paddingLeft: pixelsToUnit(14) }} />
    <ButtonText>{props.buttonText}</ButtonText>
  </MobileBase>
));

type WatchablePanelPropType = {
  pageUrl: string;
  variationCode: string;
  open: boolean;
  onPanelClose: () => void;
};

export function WatchablePanel(props: WatchablePanelPropType) {
  return (
    <Panel isOpen={props.open} onClose={props.onPanelClose} maxHeight={MODAL_HEIGHT} maxWidth={MODAL_WIDTH}>
      <Close onClick={props.onPanelClose}>
        <CloseIcon
          css={{
            width: pixelsToUnit(40),
            height: pixelsToUnit(40),
          }}
        />
      </Close>
      <Modal productCode={props.variationCode} pageUrl={props.pageUrl} onClose={props.onPanelClose} />
    </Panel>
  );
}

const Base = styled(Basic, {
  textAlign: 'center',
  margin: {
    xy: 0,
  },
  flex: {
    grow: 1,
    basis: 1,
  },
  alignItems: 'center',
  justifyContent: 'center',
});

const BaseActionbar = styled(Basic, {
  backgroundColor: (theme) => theme.buyButtonProductPageMobile,
  color:  (theme) => theme.buyButtonProductPageMobileText,
  borderRadius: pixelsToUnit(5),
  width: '30vw',
  height: '2.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
  fontWeight: 800,
  fontSize: pixelsToUnit(25)
});

const MobileBase = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  minHeight: pixelsToUnit(40),
});

const Wrapper = styled.div({
  height: '3.5rem',
  width: '3.5rem',
  margin: {
    x: pixelsToUnit(0),
    y: pixelsToUnit(5),
  },
  display: 'flex',
  alignItems: 'center',
  borderRadius: '100%',
  border: {
    xy: {
      width: thin,
      style: 'solid',
      color: lightGrey,
    },
  },
  [minMediumMediaQuery]: {
    border: 'none',
    margin: {
      x: pixelsToUnit(4),
      y: pixelsToUnit(6),
    },
  },
});

const WrapperActionbar = styled.div({
  margin: {
    x: pixelsToUnit(0),
    y: pixelsToUnit(5),
  },
  display: 'flex',
  alignItems: 'center',
  [minMediumMediaQuery]: {
    border: 'none',
    margin: {
      x: pixelsToUnit(4),
      y: pixelsToUnit(6),
    },
  },
});

const MODAL_HEIGHT = pixelsToUnit(520);
const MODAL_WIDTH = pixelsToUnit(553);

const OutOfStock = styled.span({
  border: {
    right: {
      style: 'solid',
      width: thin,
      color: snuff,
    },
  },
  color: 'red',
  fontSize: kappa,
  paddingRight: pixelsToUnit(14),
});

const ButtonText = styled.span({
  fontSize: kappa,
  marginRight: pixelsToUnit(5),
  display: 'none',
  [minTinyMediaQuery]: {
    display: 'inline',
  },
});

const EmailIcon = styled(Email, {
  fontSize: mega,
  position: 'relative',
  height: pixelsToUnit(22),
});

const Close = styled.div({
  cursor: 'pointer',
  textAlign: 'right',
  margin: {
    top: pixelsToUnit(50),
    bottom: pixelsToUnit(20),
    right: pixelsToUnit(40),
  },
});

const AddWatch = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: sigma,
  fontWeight: 'bold',
  textTransform: 'uppercase',
  letterSpacing: pixelsToUnit(0.7),
  whiteSpace: 'nowrap',
  width: '100%',
  minHeight: pixelsToUnit(50),
  [minMediumMediaQuery]: {
    fontSize: epsilon,
    letterSpacing: pixelsToUnit(0.8),
    border: {
      xy: {
        width: thin,
        style: 'solid',
        color: lightGrey,
      },
    },
  },
});
